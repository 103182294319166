import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @font-face {font-family: "Flama";
    src: url("https://db.onlinewebfonts.com/t/54f5f645f140cd68536c94c32f92d865.eot"); /* IE9*/
    src: url("https://db.onlinewebfonts.com/t/54f5f645f140cd68536c94c32f92d865.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("https://db.onlinewebfonts.com/t/54f5f645f140cd68536c94c32f92d865.woff2") format("woff2"), /* chrome firefox */
    url("https://db.onlinewebfonts.com/t/54f5f645f140cd68536c94c32f92d865.woff") format("woff"), /* chrome firefox */
    url("https://db.onlinewebfonts.com/t/54f5f645f140cd68536c94c32f92d865.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url("https://db.onlinewebfonts.com/t/54f5f645f140cd68536c94c32f92d865.svg#Flama") format("svg"); /* iOS 4.1- */
  }
  
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Flama';
  }

  body {
    background: #F3F3F3;
    -webkit-font-smoothing: antialiased;
  }

  html, body, #root {
    min-height: 100%;
    background: #F3F3F3;
  }
`;
