const styles = (theme) => ({
    '@global': {
      "* .MuiFormLabel-root.Mui-focused": {
        color: "#AAAAAA",
      },
      "* .MuiFormLabel-root": {
        color: "#AAAAAA",
      }
    },
    boxTitulo: {
      //A alterar...
      backgroundColor: '#e1e1e1',
      padding: '10px',
      marginBottom: '15px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    cellHeader: {
      fontSize: 16,
      background: '#e4e4e4',
      textAlign: 'center',
    },
    title: {
      fontSize: 22,
      marginLeft: '20px',
    },
    orderButton: {
      fontSize: 18,
      // boxShadow: 'none',
      borderRadius: 12,
      margin: theme.spacing(1),
    }
  });
  
  export default styles;