const styles = theme => ({
  boxMaisComprasTitle: {
    display: 'flex',
    flexDirection: 'column',
    height: '130px',
    justifyContent: 'center', 
  },
  comprasStyle: {
    fontWeight: 'bold',
    color: '#383939',
    '& span': {
      color: '#7A5632',
    },
  },
  cartStyle: {
    display: 'flex',
    alignSelf: 'flex-end',
    objectFit: 'contain',
  },
  titleStyle: {
    textAlign: 'center',
    fontSize: '3.4rem',
    color: '#494D4B',
    fontWeight: 'bold',
  },
  logoSoma: {
    height: 25,
    float: 'right',
    margin: 10,
  },
  logoGoogle: {
    height: 25,
    marginRight: 15,
  },
  linkStyle: {
    textDecoration: 'none',
    marginLeft: 150,
    fontSize: 13,
    color: '#494D4B',
    cursor: 'pointer',
    '&:hover': {
      color: '#7A5632',
      fontWeight: '500',
    },
  },
  buttonStyle: {
    width: 300,
    height: 50,
    color: '#dadada',
    background: '#494D4B',
    borderRadius: 8,
    fontSize: 20,
    '&:hover': {
      background: 'rgba(23, 23, 23, 0.89)',
    }
  },
  buttonGoogleStyle: {
    width: 300,
    height: 50,
    borderRadius: 8,
    color: '#494D4B',
    background: '#DEDEDE',
    fontSize: 18,
    '&:hover': {
      background: '#c7c7c7',
    },
  },
  inputStyle: {
    width: 300,
    height: 45,
    marginBottom: 10,
    '& .MuiFilledInput-root': {
      borderRadius: 8,
      color: '#616161'
    },
    '& .MuiFormLabel-root': {
      color: '#aaaaaa',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 0,
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: 0,
    },
  },
});

export default styles;