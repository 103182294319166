const styles = theme => ({
  '@global': {
    "* .MuiListItemIcon-root": {
      minWidth: 40
    },
  },
  menuButton: {
    margin: theme.spacing(1),
    color: '#494D4B',
  },
  exitButton: {
    marginLeft: '70%',
    alignItems: 'center',
  },
  exitText: {
    marginLeft: 5,
  },
  menuStyle: {
    backgroundColor: '#f3f3f3',
    color: '#494D4B',
  },
  iconStyle: {
    color: '#494D4B',
  },
  drawerHeader: {
    minHeight: 60,
    display: 'flex',
    alignItems: 'center',
    paddingTop: "12px",
  },
  iconChevron: {
    color: '#494D4B',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

export default styles;