const styles = (theme) => ({
    '@global': {
      "* .MuiFormLabel-root.Mui-focused": {
        color: "#AAAAAA",
      },
      "* .MuiFormLabel-root": {
        color: "#AAAAAA",
      }
    },
    boxStyle: {
      backgroundColor: "#FFFFFF",
      borderRadius: 15,
      width: "90%",
      margin: "auto",
    },
    inputBox: {
      marginBottom: 50,
    },
    backButton: {
      float: 'right',
    },
    title: {
      textAlign: "center",
      fontWeight: "bold",
      color: "#AD9078",
      fontSize: "4vh",
      marginBottom: 30,
    },
    buttonStyle: {
      width: "35vh",
      height: 50,
      color: "#dadada",
      background: "#494D4B",
      borderRadius: 8,
      fontSize: 20,
      "&:hover": {
        background: "rgba(23, 23, 23, 0.89)",
      },
    },
    inputStyle: {
      width: "35vh",
      height: 45,
      marginBottom: 10,
      "& .MuiFilledInput-root": {
        borderRadius: 8,
        color: "#616161",
        backgroundColor: '#E4E4E4',
      },
      "& .MuiFormLabel-root": {
        color: "#aaaaaa",
      },
      "& .MuiFilledInput-underline:after": {
        borderBottom: 0,
      },
      "& .MuiFilledInput-underline:before": {
        borderBottom: 0,
      },
      "& .MuiInputBase-input.Mui-disabled": {
        color: "#aaaaaa"
      },
      "& .MuiSelect-filled.MuiSelect-filled": {
        borderRadius: 8,
        marginBottom: -7,
        backgroundColor: '#E4E4E4',
      },
    },
    inputLabel: {
      marginLeft: 10,
      color: "#AFAFAF",
      zIndex: 1300,
    }
  });
  
  export default styles;