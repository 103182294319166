const styles = (theme) => ({
  '@global': {
    "* .MuiFormLabel-root.Mui-focused": {
      color: "#AAAAAA",
    },
    "* .MuiFormLabel-root": {
      color: "#AAAAAA",
    }
  },
  boxStyle: {
    backgroundColor: "#FFFFFF",
    borderRadius: 15,
    width: "90%",
    margin: "auto",
  },
  inputBox: {
    marginBottom: 50
  },
  backButton: {
    float: 'right',
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    color: "#AD9078",
    fontSize: "4vh",
    marginBottom: 30,
  },
  buttonStyle: {
    width: "35vh",
    height: 50,
    color: "#dadada",
    background: "#494D4B",
    borderRadius: 8,
    fontSize: 20,
    "&:hover": {
      background: "rgba(23, 23, 23, 0.89)",
    },
  },
  inputStyle: {
    borderRadius: 8,
    width: "35vh",
    height: 45,
    marginBottom: 10,
    "& .MuiFilledInput-root": {
      borderRadius: 8,
      backgroundColor: '#E4E4E4',
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiFilledInput-underline:before": {
      borderBottom: 0,
    },
    "& .MuiSelect-filled.MuiSelect-filled": {
      borderRadius: 8,
      marginBottom: -10,
      backgroundColor: '#E4E4E4',
    },
  },
  inputLabel: {
    marginTop: 3,
    marginLeft: 10,
    zIndex: 1300,
  },
  completeInput: {
    width: "35vh",
    height: 45,
    marginBottom: 10,
    "& .MuiFormControl-fullWidth": {
      borderRadius: 8,
      backgroundColor: '#E4E4E4',
      height: 56,
    },
    "& .MuiInput-underline:before": {
      border: 'none',
    },
    "& .MuiInput-underline:after": {
      border: 'none',
    },
    "& .MuiAutocomplete-endAdornment": {
      right: 7,
      top: -5,
    },
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input:first-child": {
      marginTop: 6,
    },
    "& .MuiFormLabel-root": {
      marginTop: 5,
      marginLeft: 10,
      fontSize: 16,
    },
    "& .MuiAutocomplete-input:first-child": {
      marginLeft: 10,
    },
    "& .MuiInput-underline:hover:not(.Mui-disable):before": {
      borderBottom: 0,
    }
  },
});

export default styles;