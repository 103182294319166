import React from 'react';
import {
  useState,
  useEffect,
  useCallback,
} from 'react';
// import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { 
  Button, 
  TextField,
  Box,
  Typography,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import api from '../../services/api';
import history from '../../services/history';
import SnackBar from '../../util/SnackBar';
import Loading from '../../components/Loading';

import wave from '../../assets/wave.png';
import waveMobile from '../../assets/waveMobile.png';
import cart from '../../assets/cart.png';
import logoSoma from '../../assets/logo_grupo_soma_preto.png';
// import logoGoogle from '../../assets/googleIcon.png';

import style from './styles';

const styles = theme => style(theme);

function Login({
  classes
}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const matchMaxW800 = useMediaQuery('(max-width:800px)');

  const submitLogin = useCallback(async () => {
    let user;
    try {
      setLoading(true);

      user = await api.post('/auth/login', {
        email,
        password,
      })
        .then(response => response.data);
    } catch (e) {
      return SnackBar.error('Não foi possível efetuar o Login');
    } finally {
      setLoading(false);
    }
    
    if (user && user.token) {
      localStorage.setItem('+compras:token', user.token);
      history.push('/'); 
    } else {
      SnackBar.error('Seu email ou senha estão incorretos');
    }
  }, [email, password]);

  const clickEnter = useCallback(async (event) => {
    switch(event.keyCode) {
      case 13:
        await submitLogin();
        break;
      default:
        break;
    }
  }, [submitLogin]);

  useEffect(() => {
    document.addEventListener('keydown', clickEnter);

    return () => {
      document.removeEventListener('keydown', clickEnter)
    }
  }, [clickEnter]);
  
  return (
    <>
      {loading ? (
        <>
          <Loading /> 
        </>
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="column"
            height="100vh"
            justifyContent="space-between"
            style={matchMaxW800 ? 
              {
                backgroundImage: `url(${waveMobile})`, 
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 165px',
              } : {
                backgroundImage: `url(${wave})`,
                backgroundRepeat: 'no-repeat'}
              }
            >
            <Box>
              <Box 
                className={classes.root}
                alt="Onda do Soma" 
              />
              <Box className={classes.boxMaisComprasTitle}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent={matchMaxW800 ? "center" : "flex-start"}
                >
                  <Typography 
                    className={classes.comprasStyle}
                    style={!matchMaxW800 ? 
                      {
                        marginLeft: '30px', 
                        marginTop: '30px', 
                        fontSize: '7rem'
                      } : {
                        fontSize: '15vw'
                      }
                    }
                  >
                    <span>+</span>COMPRAS
                  </Typography>
                  <img 
                    src={cart} 
                    alt="Carrinho de Compras" 
                    className={classes.cartStyle}
                    style={matchMaxW800 ? {width: '8vw'} : {}}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{ gap: 10 }}
              >
                <Typography className={classes.titleStyle}>
                  BEM-VINDO
                </Typography>
                <TextField
                  label="Digite seu e-mail" 
                  variant="filled"
                  className={classes.inputStyle}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  label="Senha" 
                  variant="filled"
                  type="password"
                  className={classes.inputStyle}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {/* <Link 
                  href="#" 
                  className={classes.linkStyle}
                >
                  esqueci minha senha ➔
                </Link> */}
                <Button 
                  className={classes.buttonStyle}
                  onClick={submitLogin}
                >
                  Entrar
                </Button>
                {/* <Button 
                  className={classes.buttonGoogleStyle}
                  href="/"
                >
                  <img 
                    src={logoGoogle} 
                    alt="Logo do Google" 
                    className={classes.logoGoogle}
                  />
                  Continuar com SOMA
                </Button> */}
              </Box>
            </Box>
            <Box>
              <img 
                src={logoSoma} 
                alt="Logo do Grupo Soma"
                className={classes.logoSoma}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

const wrapperComponent = withStyles(styles)(Login);
export default wrapperComponent;