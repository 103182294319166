import React, { 
  useState, 
  useEffect, 
} from 'react';
import { 
  Box, 
  Collapse, 
  IconButton,
} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import PersonIcon from '@material-ui/icons/Person';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DialogContent from '@material-ui/core/DialogContent';
import CreateIcon from '@material-ui/icons/Create';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import style from './styles';
import MaterialResponsibleRegister from './MaterialResponsibleRegister';
import MaterialResponsibleEdit from './MaterialResponsibleEdit';
import ConfirmDelete from '../../components/ConfirmDelete';
import Loading from '../../components/Loading';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import api from '../../services/api';
import SnackBar from '../../util/SnackBar';

const styles = (theme) => style(theme);

function MaterialResponsible({ 
  classes,
}) {
  const [loading, setLoading] = useState(false);
  const [userResponsibles, setUserResponsibles] = useState([]);
  const [search, setSearch] = useState('');
  const [rowInfo, setRowInfo] = useState(false);
  const [rowInfoEdit, setRowInfoEdit] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [reload, setReload] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openKey, setOpenKey] = useState({});
  const [userEmail, setUserEmail] = useState([]);

  useEffect(() => {
    async function userResponsibles() {
      try {
        setLoading(true);
        const response = await api.get(`/material-responsibles`)
        .then(response => response.data);

        setUserResponsibles(response);
      } catch (err) {
        SnackBar.error('Falha ao carregar informações do responsável.');
        
      } finally {
        setLoading(false);
      }
    }

    userResponsibles();
  }, [reload]);

  const handleDelete = async (usuario_id) => {
    try {
      await api.delete(`/material-responsibles/${usuario_id}`)
      SnackBar.success('Responsável deletado com sucesso.');
      setReload(!reload);
    } catch (err) {
      SnackBar.error('Falha ao deletar responsável.');
    }
  }

  const handleEdit = async (email) => {
    try{
      const response = await api.get(`/material-responsibles/${email}`)
      .then(response => response.data);

      setUserEmail(response);
    } catch (err) {
      SnackBar.error('Falha ao pegar email');
    }
  }
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenEdit = (row) => {
    setRowInfoEdit(row['email']);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCallEdit = (row) => {
    const email = row['email'];
    handleEdit(email);
  }

  const columns = [
    {
      id: "email",
      label: "Responsável",
      align: "center",
    },
    {
      id: "grupo",
      label: "Grupo",
      align: "center",
    },
  ];

  const subgrupos = [
    {
      id: "subgroup",
      label: "Subgrupo",
      align: "center",
    },
  ];

  return (
    <>
      {loading ? (
        <>
          <Header />
          <Loading /> 
        </>
      ) : (
        <>
          <Box 
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            height="100vh"
          >
            <Box
              display='flex'
              flexDirection='column'  
            >
              <Header />              
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              flex={1}
            >
              <Box className={classes.boxStyle}>
                <span className={classes.title}>
                  <PersonIcon 
                    fontSize="large" 
                    className={classes.iconStyle}
                  />
                  Administração de Cargos
                </span>
                <Button 
                  variant="contained"
                  className={classes.button}
                  startIcon={<AddCircleIcon />}
                  onClick={handleClickOpen}
                >
                  NOVO
                </Button>
                <Dialog onClose={handleClose} open={open}>
                  <DialogContent spacing={3} dividers>
                    <MaterialResponsibleRegister 
                      onClickClose={() => handleClose()}
                      onReload={() => setReload(!reload)}
                    />
                  </DialogContent>
                </Dialog>
                <Dialog onClose={handleCloseEdit} open={openEdit}>
                  <DialogContent spacing={3} dividers>
                    <MaterialResponsibleEdit 
                      onClickClose = {() => handleCloseEdit()}
                      onEmail = {rowInfoEdit}
                      onReload = {() => setReload(!reload)}
                    />
                  </DialogContent>
                </Dialog>
                <Dialog open={openDelete}>
                  <DialogContent dividers>
                    <ConfirmDelete
                      onClickCancel={() => {
                        setOpenDelete(false);
                      }}
                      onClickConfirm={() => {
                        handleDelete(rowInfo['usuario_id']);
                        setOpenDelete(false);
                      }}
                      message="Deseja excluir este responsável e todos os seus subgrupos?"
                    />
                  </DialogContent>
                </Dialog>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Paper className={classes.root}>
                  <TableContainer>
                    <InputBase
                      className={classes.inputStyle}
                      placeholder="Procurar por responsável"
                      value={search}
                      onChange={(ev) => setSearch(ev.target.value)}
                    />
                    <Table stickyHeader>
                      <TableHead  >
                        <TableRow >
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              className={classes.cellHeader}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                          {subgrupos.map((column) => (
                            <TableCell
                              key={column.id}
                              className={classes.cellHeader}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                          <TableCell style={{ width: 30 }} className={classes.cellHeader}></TableCell>
                          <TableCell style={{ width: 30 }} className={classes.cellHeader}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userResponsibles.filter(user => user['email'].includes(search.toLowerCase())).map((row, index) => {
                          return (
                            <>
                              <TableRow key={index}>
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell key={index + column.id} align={column.align}>
                                      {value} 
                                    </TableCell>
                                  );
                                })}
                                <TableCell align="center" >
                                  <IconButton
                                    aria-label="expand row"
                                    onClick={() => { 
                                      setOpenKey((sub) => ({
                                        [row.email]: !sub[row.email] }))
                                      handleCallEdit(row)
                                    }}
                                  >
                                    {openKey[row.email] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>                                                                                            
                                <Collapse in={openKey[row.email]} timeout="auto" unmountOnExit >
                                    <Box>
                                      <Table >
                                        <TableBody>
                                          {userEmail.map((row, index) => {
                                            return (
                                              <TableRow>
                                                {subgrupos.map((subgrupo) => {
                                                  const value = row[subgrupo.id];      
                                                  return (
                                                    <TableCell key={index + subgrupo.id} align={subgrupo.align}  className={classes.cellCollapse} >
                                                      {value}
                                                    </TableCell>
                                                  );
                                                })}
                                              </TableRow>
                                            );
                                          })}
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </Collapse>                               
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton 
                                    variant="contained"
                                    onClick={() => {
                                      handleClickOpenEdit(row);
                                    }}
                                  >
                                    <CreateIcon/>
                                  </IconButton>
                                </TableCell>
                                <TableCell>
                                  <IconButton 
                                    onClick={() => {
                                      setOpenDelete(true);
                                      setRowInfo(row);
                                    }}
                                  >
                                    <ClearIcon/>
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </Box>
            <Footer />
          </Box>
        </>
      )}
    </>
  );
}

const wrapperComponent = withStyles(styles)(MaterialResponsible);
export default wrapperComponent;