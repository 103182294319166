import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  TextField,
  Typography,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@material-ui/core";
import api from "../../../services/api";
import style from "./styles";
import SnackBar from "../../../util/SnackBar";

const styles = (theme) => style(theme);

function PreOrderValidationUpdate({
  classes,
  orderId,
  material,
  materialColor,
  materialDescription,
  originalAmount,
  cost,
  orderUnitConversionFactor,
  orderUnit,
  paymentDays,
  shippingCompany,
  discount,
  onClickClose,
  onReload,
}) {
  const [originalAmountUpdate, setOriginalAmountUpdate] = useState(originalAmount);
  const [costUpdate, setCostUpdate] = useState(cost);
  const [orderUnitConversionFactorUpdate, setOrderUnitConversionFactorUpdate] = useState(orderUnitConversionFactor);
  const [orderUnitUpdate, setOrderUnitUpdate] = useState(orderUnit);
  const [suppliersUnit, setSuppliersUnit] = useState([]);

  useEffect(() => {
    async function getSupplierUnit() {
      try {
        const response = await api
          .get("/purchases/supplier-unit")
          .then((response) => response.data);
        setSuppliersUnit(response);
      } catch (err) {
        SnackBar.error("Falha ao carregar informações da Unidade Fornecedor.");
      }
    }
    getSupplierUnit();
  }, []);

  const handleSubmit = async () => {
    try {
      await api.put(`/purchases/${orderId}`, {
        originalAmount: originalAmountUpdate,
        cost: costUpdate,
        orderUnitConversionFactor: orderUnitConversionFactorUpdate,
        orderUnit: orderUnitUpdate,
        material: material,
        materialColor: materialColor
      });
      SnackBar.success("Pedido alterado com sucesso!");
      onClickClose();
      onReload();
    } catch (err) {
      SnackBar.error("Não foi possível alterar esse pedido");
    }
  };

  return (
    <>
      <Box className={classes.boxStyle}>
        <Typography className={classes.title}>
          <span>ATUALIZAÇÃO DO PEDIDO</span>
        </Typography>
        <Box
          className={classes.inputBox}
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ gap: 10 }}
        >
          <TextField
            value={material}
            label="Material"
            variant="filled"
            className={classes.inputStyle}
            color="#AFAFAF"
            disabled
          />

          <TextField
            value={materialColor}
            label="Cor Material"
            variant="filled"
            type="number"
            className={classes.inputStyle}
            disabled
          />

          <TextField
            value={materialDescription}
            label="Descrição Material"
            variant="filled"
            className={classes.inputStyle}
            disabled
          />

          <TextField
            value={originalAmountUpdate}
            label="Quantidade Original"
            variant="filled"
            type="number"
            className={classes.inputStyle}
            onChange={(e) =>
              e.target.value < 0
                ? setOriginalAmountUpdate((e.target.value = 0))
                : setOriginalAmountUpdate(e.target.value)
            }
          />

          <TextField
            value={costUpdate}
            label="Custo"
            variant="filled"
            type="number"
            inputProps={{ step: 0.01 }}
            className={classes.inputStyle}
            onChange={(e) =>
              e.target.value < 0
                ? setCostUpdate((e.target.value = 0))
                : setCostUpdate(e.target.value)
            }
          />

          <TextField
            value={orderUnitConversionFactorUpdate}
            label="Fator Conversão Unidade Pedido"
            variant="filled"
            type="number"
            inputProps={{ step: 0.01 }}
            className={classes.inputStyle}
            onChange={(e) =>
              e.target.value < 0
                ? setOrderUnitConversionFactorUpdate((e.target.value = 0))
                : setOrderUnitConversionFactorUpdate(e.target.value)
            }
          />

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel className={classes.inputLabel}>
              Unidade Pedido
            </InputLabel>
            <Select
              value={orderUnitUpdate}
              disableUnderline
              label="Unidade Pedido"
              variant="filled"
              className={classes.inputStyle}
              onChange={(e) => setOrderUnitUpdate(e.target.value)}
            >
              {suppliersUnit.map((index) => {
                return (
                  <MenuItem value={index.supplierUnit}>
                    {index.supplierUnit}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            value={paymentDays}
            label="Condição pagamento"
            variant="filled"
            type="number"
            className={classes.inputStyle}
            disabled
          />

          <TextField
            value={shippingCompany}
            label="Transportadora"
            variant="filled"
            className={classes.inputStyle}
            disabled
          />

          <TextField
            value={discount}
            label="Desconto"
            variant="filled"
            type="number"
            className={classes.inputStyle}
            disabled
          />

          <Button className={classes.buttonStyle} onClick={handleSubmit}>
            Atualizar
          </Button>
        </Box>
      </Box>
    </>
  );
}

const wrapperComponent = withStyles(styles)(PreOrderValidationUpdate);
export default wrapperComponent;
