const styles = theme => ({
  dateField: {
    marginLeft: 10,
  },
  flexButton: {
    marginTop: 15,
    boxShadow: 'none',
    borderRadius: 12,
    margin: theme.spacing(1),
  },
  filterText: {
    backgroundColor: '#FFB0B0', 
    padding: 5,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 5,
  },
});

export default styles;