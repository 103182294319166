import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { 
  Box, 
  Typography, 
  Button, 
} from '@material-ui/core';

import style from './styles';

const styles = (theme) => style(theme);

function ConfirmDelete({ 
  classes,
  onClickConfirm,
  onClickCancel,
  message,
}) {
  return (
    <>
      <Box>
        <Typography className={classes.title}>
          <span>{message}</span>
        </Typography>
        <Box
          display="flex"
          sx={{ gap: 20 }}
        >
          <Button
            variant="contained" 
            onClick={onClickConfirm}
            className={classes.confirmStyle}
          >
            Confirmar
          </Button>
          <Button
            variant="contained"
            onClick={onClickCancel}
            className={classes.cancelStyle}
          >
            Cancelar
          </Button>
        </Box>
      </Box>
    </>
  );
}

const wrapperComponent = withStyles(styles)(ConfirmDelete);
export default wrapperComponent;
