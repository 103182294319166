import React, { useState, useEffect } from "react";
import { Box, Button, IconButton, useMediaQuery } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Loading from "../../components/Loading";
import Header from "../../components/Header";
import api from "../../services/api";
import SnackBar from "../../util/SnackBar";
import Checkbox from "@material-ui/core/Checkbox";
import PreOrderValidationDetails from "./PreOrderValidationDetails";
import PreOrderErrorApproval from "./PreOrderErrorApproval"
import ConfirmDelete from "../../components/ConfirmDelete/index";
import Footer from "../../components/Footer";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import style from "./styles";

import AssignmentIcon from "@material-ui/icons/Assignment";

const styles = (theme) => style(theme);

function PreOrderValidation({ classes }) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [orderId, setOrderId] = useState("");
  const [supplier, setSupplier] = useState("");
  const [order, setOrder] = useState([]);
  const [msg, setMsg] = useState([]);

  const [preOrderValidation, setPreOrderValidation] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [openConfirm, setOpenConfirm] = useState();
  const [openCancel, setOpenCancel] = useState();
  const [reload, setReload] = useState();
  const [checked, setChecked] = useState([]);
  const [openError, setOpenError] = useState(false);

  const matchMinW640 = useMediaQuery("(min-width:640px)");

  useEffect(() => {
    async function preOrdersInfo() {
      try {
        setLoading(true);
        const response = await api
          .get("/purchases/pre-orders")
          .then((response) => response.data);
        setPreOrderValidation(response);
      } catch (err) {
        SnackBar.error("Falha ao carregar informações dos pré-pedidos.");
      } finally {
        setLoading(false);
      }
    }
    preOrdersInfo();
  }, [reload]);

  let ordersParams = [];

  const splitArray = (arrayChecked) => {
    while (arrayChecked.length) {
      ordersParams.push(arrayChecked.splice(0, 3));
    }
  }

  const refresh = () => window.location.reload();

  const handleSubmit = async () => {
    splitArray(checked);
    
    let response;
    try {
      setLoading(true)
      response = await api.post("/purchases/multiple-order-approval", {
        checked: `${ordersParams}`,
      })
      .then((response) => response.data);

      let dataArray = [];
      const res = response.msgReturn;
      res.map((datas) => {
        if (datas.status === "Erro") {
          dataArray.push(datas);

          dataArray.map((item) => {
            order.push(item.moreShoppingOrderId);
            msg.push(item.msg);
            return (order, msg)
          });

          return new Promise(() => {
            setTimeout(() => {
              openErrorModal(order, msg);
              setLoading(false);
            }, 2000);
          });
        }
        return res;
      });
      
      const orderOk = res.some((item) => {
        return item.status !== "Erro";
      });
      if (orderOk) {
        SnackBar.success(`Pedido(s) aprovado(s) com sucesso.`);
        if (dataArray.length < 1) {
          refresh();
        }
      }
    } catch (err) {
      SnackBar.error("Falha ao aprovar pedido(s).");
    }
  };
  

  const handleCancel = async () => {
    splitArray(checked);

    try {
      setLoading(true)
      await api.post("/purchases/multiple-cancel-purchase", {
        checked: `${ordersParams}`,
      });
      setLoading(false);
      SnackBar.success("Pedidos cancelados com sucesso!");
      refresh();
    } catch (err) {
      SnackBar.error("Falha ao cancelar pedidos.");
    }
  };

  const openCancelModal = () => setOpenCancel(true);

  const closeCancelModal = () => setOpenCancel(false);

  const openConfirmModal = () => setOpenConfirm(true);

  const closeConfirmModal = () => setOpenConfirm(false);

  const openDetailsModal = (orderId, supplier) => {
    setOrderId(orderId);
    setSupplier(supplier);
    setOpenDetails(true);
  };

  const closeDetailsModal = () => {
    setOpenDetails(false);
    setReload(!reload);
  };
  
  const openErrorModal = (order, msg) => {
    setOrder(order);
    setMsg(msg);
    setOpenError(true);
  };

  const closeErrorModal = () => {
    setOpenError(false);
    // setIsChecked(!isChecked);
    refresh();
  };

  const columns = [
    {
      id: "orderId",
      label: "Nº do Pedido",
      align: "center",
    },
    {
      id: "supplier",
      label: "Fornecedor",
      align: "center",
    },
    {
      id: "quantityMaterialsByOrder",
      label: "Quantidade de material",
      align: "center",
    },
    {
      id: "itensQuantity",
      label: "Quantidade de itens",
      align: "center",
    },
    {
      id: "totalOrderAmount",
      label: "Valor Total",
      align: "center",
    },
    {
      id: "creationDate",
      label: "Emissão",
      align: "center",
    },
    {
      id: "createdBy",
      label: "Criado por",
      align: "center",
    },
  ];

  const formatDate = (value) => {
    let year = value.substring(0, 4);
    let month = value.substring(5, 7);
    let day = value.substring(8, 10);

    return `${day}/${month}/${year}`;
  }

  const handleToggle = (orderId) => () => {
    const currentIndex = checked.indexOf(orderId);
    const newChecked = checked;

    if (currentIndex === -1) {
      newChecked.push(orderId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <>
      {loading ? (
        <>
          <Header />
          <Loading />
        </>
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100vh"
          >
            <Box display="flex" flexDirection="column">
              <Header />
            </Box>
            <Box display="flex" flexDirection="column" flex={1}>
              <Box className={classes.boxStyle}>
                <span className={classes.title}>
                  <AssessmentOutlinedIcon
                    fontSize="large"
                    className={classes.iconStyle}
                  />
                  Validação de Pré-pedidos
                </span>
                <Box>
                  <Button
                    variant="contained"
                    className={classes.orderButton}
                    onClick={() => openCancelModal()}
                  >
                    Cancelar pedidos
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.orderButton}
                    onClick={() => openConfirmModal()}
                  >
                    Aprovar pedidos
                  </Button>
                </Box>
              </Box>
              <Dialog
                onClose={closeDetailsModal}
                open={openDetails}
                maxWidth={"xl"}
              >
                <DialogContent dividers>
                  <PreOrderValidationDetails
                    orderId={orderId}
                    supplier={supplier}
                    onClickClose={() => closeDetailsModal()}
                    onReload={() => setReload(!reload)}
                  />
                </DialogContent>
              </Dialog>
              <Dialog onClose={closeConfirmModal} open={openConfirm}>
                <DialogContent dividers>
                  <ConfirmDelete
                    onClickCancel={() => closeConfirmModal()}
                    onClickConfirm={() => handleSubmit(closeConfirmModal())}
                    message="Deseja aprovar estes pedidos?"
                  />
                </DialogContent>
              </Dialog>
              <Dialog onClose={closeCancelModal} open={openCancel}>
                <DialogContent dividers>
                  <ConfirmDelete
                    onClickCancel={() => closeCancelModal()}
                    onClickConfirm={() => handleCancel(closeCancelModal())}
                    message="Deseja cancelar estes pedidos?"
                  />
                </DialogContent>
              </Dialog>
              <Dialog onClose={closeErrorModal} open={openError} maxWidth={"xl"}>
                <DialogContent dividers>
                  <PreOrderErrorApproval
                    order={order}
                    msg={msg}
                    onClickClose={() => closeErrorModal()}
                    onReload={() => setReload(!reload)}
                  />
                </DialogContent>
              </Dialog>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                className={classes.container}
              >
                <Paper className={classes.root}>
                  <TableContainer>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.boxFixed}
                      style={matchMinW640 ? {} : { justifyContent: "center" }}
                    >
                      <InputBase
                        className={classes.inputStyle}
                        placeholder="Procurar por número do pedido"
                        value={search}
                        onChange={(ev) => setSearch(ev.target.value)}
                      />
                    </Box>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ maxWidth: 100 }}
                            className={classes.cellHeader}
                            align="center"
                          >
                            Selecionar pedido
                          </TableCell>
                          <TableCell
                            style={{ maxWidth: 30 }}
                            className={classes.cellHeader}
                            align="center"
                          >
                            Detalhes
                          </TableCell>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              style={{ width: 300 }}
                              className={classes.cellHeader}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {preOrderValidation
                          .filter((user) =>
                            user["orderId"]
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          )
                          .map((row, index) => {
                            return (
                              <TableRow hover key={index}>
                                <TableCell align="center">
                                  <Checkbox
                                    onClick={handleToggle(row.orderId)}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      openDetailsModal(
                                        row["orderId"],
                                        row["supplier"]
                                      )
                                    }
                                  >
                                    <AssignmentIcon />
                                  </IconButton>
                                </TableCell>
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  if (value === row["creationDate"]) {
                                    const dateValue = formatDate(value);
                                    return (
                                      <TableCell
                                        key={index + column.id}
                                        align={column.align}
                                      >
                                        {dateValue}
                                      </TableCell>
                                    );
                                  }
                                  return (
                                    <TableCell
                                      key={index + column.id}
                                      align={column.align}
                                    >
                                      {value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </Box>
            <Footer />
          </Box>
        </>
      )}
    </>
  );
}

const wrapperComponent = withStyles(styles)(PreOrderValidation);
export default wrapperComponent;
