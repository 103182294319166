const styles = theme => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.7em',
      height: '0.7em',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  box: {
    margin: theme.spacing(3),
  },
  card: {
    display:"flex",
    flexDirection:"row",
    flexWrap:"wrap",
    justifyContent:"center",
    alignItems:"center",
    padding: "0px 170px 0px 170px !important"
  },
  title: {
    textAlign: 'center',
    fontSize: 22,
    color: "#707070",
    display: 'flex',
    alignItems: 'center',
  },
  boxStyle: {
    height: '3vh',
    width: "100%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0px 20px 0px',
  },
});

export default styles;