import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@material-ui/core";
import api from "../../../services/api";
import style from "./styles";
import SnackBar from "../../../util/SnackBar";

const styles = (theme) => style(theme);

function PreOrderUpdateSupplier({
  classes,
  orderId,
  supplier,
  onClickClose,
  onReload,
}) {
  const [supplierUpdate, setSupplierUpdate] = useState(supplier);
  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
    async function getSuppliers() {
      try {
        const response = await api
          .get("/purchases/suppliers")
          .then((response) => response.data);
        setSuppliers(response);
      } catch (err) {
        SnackBar.error("Falha ao carregar informações do Fornecedor.");
      }
    }
    getSuppliers();
  }, []);

  const handleSubmit = async () => {
    try {
      await api.post(`/purchases/update-supplier`, {
        moreShoppingOrderId: orderId,
        supplier: supplierUpdate,
      });
      SnackBar.success("Fornecedor alterado com sucesso!");
      onClickClose();
      onReload();
    } catch (err) {
      SnackBar.error("Não foi possível atualizar o fornecedor");
    }
  };

  return (
    <>
      <Box className={classes.boxStyle}>
        <Typography className={classes.title}>
          <span>ATUALIZAÇÃO DE FORNECEDOR</span>
        </Typography>
        <Box
          className={classes.inputBox}
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ gap: 10 }}
        >
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel className={classes.inputLabel}>Fornecedor</InputLabel>
            <Select
              value={supplierUpdate}
              disableUnderline
              label="Fornecedor"
              variant="filled"
              className={classes.inputStyle}
              onChange={(e) => setSupplierUpdate(e.target.value)}
            >
              {suppliers.map((index) => {
                return (
                  <MenuItem value={index.supplier}>{index.supplier}</MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Button className={classes.buttonStyle} onClick={handleSubmit}>
            Atualizar
          </Button>
        </Box>
      </Box>
    </>
  );
}

const wrapperComponent = withStyles(styles)(PreOrderUpdateSupplier);
export default wrapperComponent;
