import React, { 
  useState, 
  useEffect, 
  useRef,
} from "react";
import { 
  Box,
  Button,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import HistoryIcon from "@material-ui/icons/History";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AssignmentIcon from "@material-ui/icons/Assignment";

import Loading from "../../components/Loading";
import Header from "../../components/Header";
import api from "../../services/api";
import SnackBar from "../../util/SnackBar";
import Footer from "../../components/Footer";
import OrderHistoryDetails from "./OrderHistoryDetails";
import FilterDate from '../../components/FilterDate'

import {
  formatFirstDayCurrentMonth,
  formatCurrentDay
} from '../../util/UtilDates';

import ReactExport from "react-export-excel";

import style from "./styles";
const styles = (theme) => style(theme);

function OrderHistory({ 
  classes, 
}) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [orders, setOrders] = useState([]);
  const [dateInitial, setDateInitial] = useState(formatFirstDayCurrentMonth());
  const [dateFinal, setDateFinal] = useState(formatCurrentDay());
  const dateInitialFieldReference = useRef(null);
  const dateFinalFieldReference = useRef(null);
  const [reload, setReload] = useState();
  const [orderId, setOrderId] = useState("");
  const [openDetails, setOpenDetails] = useState(false);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const matchMinW640 = useMediaQuery('(min-width:640px)');

  useEffect(() => {
    async function orders() {
      try {
        setLoading(true);
        const response = await api.get('/purchases/approved')
          .then((response) => response.data);
          
        setOrders(response);
      } catch (err) {
        SnackBar.error("Falha ao carregar informações dos pedidos.");

      } finally {
        setLoading(false);
      }
    }
    orders();
  }, [reload]);

  const onClickFilterDate = () => {
    setDateInitial(dateInitialFieldReference.current.value);
    setDateFinal(dateFinalFieldReference.current.value);
  } 

  const openDetailsModal = (orderId) => {
    setOrderId(orderId);
    setOpenDetails(true);
  };

  const closeDetailsModal = () => {
    setOpenDetails(false);
    setReload(!reload);
  };

  const columns = [
    {
      id: "creationDate",
      label: "Data do Pedido",
      align: "center",
    },
    {
      id: "orderStatus",
      label: "Status",
      align: "center",
    },
    {
      id: "orderId",
      label: "Nº Pedido MC",
      align: "center",
    },
    {
      id: "orderSoma",
      label: "Nº Pedido Soma",
      align: "center",
    },
    {
      id: "supplier",
      label: "Fornecedor",
      align: "center",
    },
    {
      id: "quantityMaterialsByOrder",
      label: "Quantidade de material",
      align: "center",
    },
    {
      id: "itensQuantity",
      label: "Quantidade de itens",
      align: "center",
    },
  ];

  function formatDate(value) {
    let year = value.substring(0, 4);
    let month = value.substring(5, 7);
    let day = value.substring(8, 10);

    return `${day}/${month}/${year}`;
  }

  return (
    <>
      {loading ? (
        <>
          <Header />
          <Loading />
        </>
      ) : (
        <>
          <Box 
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100vh"
          >
            <Box
              display="flex"
              flexDirection="column"  
            >
              <Header />              
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
            >
              <Box className={classes.boxStyle}>
                <span className={classes.title}>
                  <HistoryIcon 
                    fontSize="large" 
                    className={classes.iconStyle} 
                  />
                  Histórico de Pedidos
                </span>
              </Box>
              <Dialog
                onClose={closeDetailsModal}
                open={openDetails}
                maxWidth={"xl"}
              >
                <DialogContent dividers>
                  <OrderHistoryDetails
                    orderId={orderId}
                    onClickClose={() => closeDetailsModal()}
                    onReload={() => setReload(!reload)} 
                  />
                </DialogContent>
              </Dialog>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                margin="10px"
              >
                <Paper className={classes.root}>
                  <TableContainer>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.boxFixed}
                      style={matchMinW640 ? {} : { justifyContent: 'center' }}
                    >
                      <InputBase
                        className={classes.inputStyle}
                        placeholder="Procurar por Nº Pedido MC ou Fornecedor"
                        value={search}
                        onChange={(ev) => setSearch(ev.target.value)}
                      />
                      <ExcelFile element={
                        <Button variant="contained" className={classes.exportButton}>
                          Exportar para excel
                        </Button>
                      }>
                        <ExcelSheet data={
                          orders.filter((order) => { 
                            const initial = new Date(dateInitial)
                            const final = new Date(dateFinal) 
                            const creation = new Date(order.creationDate)
                            if (
                              (order["orderId"].toLowerCase().includes(search.toLowerCase()) ||
                              order["supplier"].toLowerCase().includes(search.toLowerCase())) &&
                              (creation >= initial && creation <= final)
                            ) {
                              return true;
                            }
                            return false;
                          })
                        } name="Histórico">
                          {columns.map((column) => (
                            <ExcelColumn
                              label={column.label} value={column.id}
                            />
                          ))}
                        </ExcelSheet>
                      </ExcelFile>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <FilterDate
                          dateInitial={dateInitial}
                          dateFinal={dateFinal}
                          onClick={onClickFilterDate}
                          inputReferenceInitial={dateInitialFieldReference}
                          inputReferenceFinal={dateFinalFieldReference}
                        />
                      </Box>
                    </Box>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ maxWidth: 30 }}
                            className={classes.cellHeader}
                            align="center"
                          >
                            Detalhes
                          </TableCell>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              style={{ width: 300 }}
                              className={classes.cellHeader}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orders
                          .filter((order) => {
                            return (
                              (
                                order['orderId'].toLowerCase().includes(search.toLowerCase()) ||
                                order['supplier'].toLowerCase().includes(search.toLowerCase())
                              ) &&
                              new Date(order['creationDate']) >= new Date(dateInitial) &&
                              new Date(order['creationDate']) <= new Date(dateFinal)
                            )
                          }
                          ).map((row, index) => {
                            return (
                              <TableRow hover key={index}>
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      openDetailsModal(
                                        row["orderId"]
                                      )
                                    }
                                  >
                                    <AssignmentIcon />
                                  </IconButton>
                                </TableCell>
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  if (value === row["creationDate"]) {
                                    const dateValue = formatDate(value)
                                    return (
                                      <TableCell
                                        key={index + column.id}
                                        align={column.align}
                                      >
                                        {dateValue}
                                      </TableCell>
                                    )
                                  }
                                  return (
                                    <TableCell
                                      key={index + column.id}
                                      align={column.align}
                                    >
                                      {value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </Box>
            <Footer />
          </Box>
        </>
      )}
    </>
  );
}

const wrapperComponent = withStyles(styles)(OrderHistory);
export default wrapperComponent;