import React, { 
  useContext, 
  useState, 
  useEffect 
} from 'react';

import jwt_decode from "jwt-decode";

const UserContext = React.createContext();

const decodeUser = (tok) => {
  if (!tok) return undefined;
  
  try {
    const [, data,] = tok.split('.');
    return JSON.parse(atob(data.replace('-', '+').replace('_', '\\')));
  } catch (e){
    return undefined
  }
}

const UserContextProvider = (props) => {
  const token = localStorage.getItem('+compras:token');
  const [user, setUser] = useState(decodeUser(token));

  useEffect(() => {
    setUser(decodeUser(token));
  }, [token]);

  return (
    <UserContext.Provider value={{ user }} >
      {props.children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const { user } = useContext(UserContext);

  return user;
};

const IsAuthenticated = () => localStorage.getItem('+compras:token') !== null;

const PermissionsGate = () => {
  const token = localStorage.getItem('+compras:token');
 
  const decoded = jwt_decode(token);
  
  let permission = decoded.email;

  return permission;
}

const Role = {
  Escrita: 'escrita',
  Consulta: 'consulta'
}

const UserConsulta = {
  EmailConsulta: 'consultarelatorios@maiscompras.com.br'
}

const emailUsername =() => {
  let userName = PermissionsGate();
  return userName.split('@')[0]
}

const logoff = () => localStorage.removeItem('+compras:token');

export { UserContextProvider, IsAuthenticated, logoff, PermissionsGate, Role, UserConsulta, emailUsername};
export default useUser;