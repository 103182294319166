import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import style from "./styles";
import api from "../../../services/api";
import SnackBar from "../../../util/SnackBar";

const styles = (theme) => style(theme);

function OrderHistoryDetails({
  classes, 
  orderId}) {

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const orders = async (orderId) => {
      try {
        const response = await api.post("/purchases/order-history-details", {
          orderId: `${orderId}`,
        }).then((response) => response.data);
        setOrders(response);
      } catch (err) {
        SnackBar.error("Falha ao carregar informações do pedido.");
      }
    };
    orders(orderId);
  }, [orderId]);

  const columns = [
    {
      id: "material",
      label: "Material",
      align: "center",
    },
    {
      id: "materialColor",
      label: "Cor Material",
      align: "center",
    },
    {
      id: "materialDescription",
      label: "Descrição Material",
      align: "center",
    },
    {
      id: "cost",
      label: "Valor",
      align: "center",
    },
    {
      id: "originalAmount",
      label: "Quantidade Original",
      align: "center",
    },
    {
      id: "shippingCompany",
      label: "Transportadora",
      align: "center",
    },
    {
      id: "createdBy",
      label: "Requerido por:",
      align: "center",
    },
    {
      id: "approvedBy",
      label: "Aprovado / Cancelado por:",
      align: "center",
    },
  ];

  return (
    <>
      <Box  align="center">
        <Box className={classes.boxTitulo}>
          <span className={classes.title}>PEDIDO: {orderId}</span>
        </Box>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{ width: 300 }}
                      className={classes.cellHeader} 
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((row, index) => {
                  return (
                    <TableRow hover key={index}>
                        {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={index + column.id}
                            align={column.align}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}

const wrapperComponent = withStyles(styles)(OrderHistoryDetails);
export default wrapperComponent;