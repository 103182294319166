import React, {
  useState,
} from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import api from "../../../services/api";
import style from "./styles";
import Snackbar from "../../../util/SnackBar";
const styles = (theme) => style(theme);
function MrpStockUpdate({
  classes,
  material,
  materialColor,
  materialDescription,
  minimumStockDays,
  maximumStockDays,
  deliveryDays,
  itemStatus,
  onClickClose,
  onReload,
}) 
{
  const [materialUpdate, setMaterialUpdate] = useState(material);
  const [materialColorUpdate, setMaterialColorUpdate] = useState(materialColor);
  const [materialDescriptionUpdate, setMaterialDescriptionUpdate] = useState(materialDescription);
  const [minimumStockDaysUpdate, setMinimumStockDaysUpdate] = useState(minimumStockDays);
  const [maximumStockDaysUpdate, setMaximumStockDaysUpdate] = useState(maximumStockDays);
  const [deliveryDaysUpdate, setDeliveryDaysUpdate] = useState(deliveryDays);
  const [itemStatusUpdate, setItemStatusUpdate] = useState(itemStatus);

  const handleSubmit = async () => {
    try {
      await api.put("/mrp-stocks", {
        material: materialUpdate,
        materialColor: materialColorUpdate,
        materialDescription: materialDescriptionUpdate,
        minimumStockDays: minimumStockDaysUpdate,
        maximumStockDays: maximumStockDaysUpdate,
        deliveryDays: deliveryDaysUpdate,
        itemStatus: itemStatusUpdate,
      });
      Snackbar.success("Material alterado com sucesso!");
      onClickClose();
      onReload();
    } catch (err) {
      Snackbar.error("Não foi possível atualizar esse material");
    }
  };
  
  return (
    <>
      <Box className={classes.boxStyle}>
        <Typography className={classes.title}>
          <span>ATUALIZAÇÃO DO ESTOQUE</span>
        </Typography>
        <Box
          className={classes.inputBox}
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ gap: 10 }}
        >

          <TextField
            value={materialUpdate}
            label="Material"
            variant="filled"
            className={classes.inputStyle}
            onChange={(e) => setMaterialUpdate(e.target.value)}
            disabled
          />

          <TextField
            value={materialColorUpdate}
            label="Cor Material"
            variant="filled"
            type="number"
            className={classes.inputStyle}
            onChange={(e) => setMaterialColorUpdate(e.target.value)}
            disabled
            color="#AFAFAF"
          />

          <TextField
            value={materialDescriptionUpdate}
            label="Descrição do Material"
            variant="filled"
            className={classes.inputStyle}
            onChange={(e) => setMaterialDescriptionUpdate(e.target.value)}
            disabled
            color="#AFAFAF"
          />

          <TextField
            value={minimumStockDaysUpdate}
            label="Dias Estoque Mínimo"
            variant="filled"
            type="number"
            className={classes.inputStyle}
            onChange={(e) =>
              e.target.value < 0
                ? setMinimumStockDaysUpdate((e.target.value = 0))
                : setMinimumStockDaysUpdate(e.target.value)
            }
          />

          <TextField
            value={maximumStockDaysUpdate}
            label="Dias Estoque Máximo"
            variant="filled"
            type="number"
            className={classes.inputStyle}
            onChange={(e) =>
              e.target.value < 0
                ? setMaximumStockDaysUpdate((e.target.value = 0))
                : setMaximumStockDaysUpdate(e.target.value)
            }
          />

          <TextField
            value={deliveryDaysUpdate}
            label="Dias de Entrega"
            variant="filled"
            type="number"
            className={classes.inputStyle}
            onChange={(e) =>
              e.target.value < 0
                ? setDeliveryDaysUpdate((e.target.value = 0))
                : setDeliveryDaysUpdate(e.target.value)
            }
          />

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel className={classes.inputLabel}>Status</InputLabel>
            <Select
              value={itemStatusUpdate}
              disableUnderline
              label="Status"
              variant="filled"
              className={classes.inputStyle}
              onChange={(e) => setItemStatusUpdate(e.target.value)}
            >
              <MenuItem value={"ATIVO"}>ATIVO</MenuItem>
              <MenuItem value={"INATIVO"}>INATIVO</MenuItem>
            </Select>
          </FormControl>

          <Button className={classes.buttonStyle} onClick={handleSubmit}>
            Atualizar
          </Button>
        </Box>
      </Box>
    </>
  );
}
const wrapperComponent = withStyles(styles)(MrpStockUpdate);
export default wrapperComponent;