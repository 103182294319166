const styles = (theme) => ({
  '@global':{
    '*::-webkit-scrollbar': {
      width: '0.7em',
      height: '0.7em',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  root: {
    width: '100%',
    boxShadow: 'none',
    '& .MuiTableContainer-root': {
      height: '79vh',
    },
  },
  boxStyle: {
    height: '3vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0px 10px 0px',
  },
  iconStyle: {
    color: '#707070',
    margin: 8,
  },
  title: {
    textAlign: 'center',
    fontSize: 22,
    color: '#707070',
    display: 'flex',
    alignItems: 'center',
  },
  inputStyle: {
    width: 280,
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 10,
    border: '2px solid #F3F3F3',
    borderRadius: 10,
    backgroundColor: '#FDFDFD',
    '& .MuiInputBase-input': {
      marginLeft: 8,
      color: '#707070',
      fontSize: 14,
    },
  },
  cellHeader: {
    fontSize: 16,
    background: '#e4e4e4',
    textAlign: 'center',
  },
  exportButton: {
    boxShadow: 'none',
    borderRadius: 12,
    margin: theme.spacing(1),
  },
});

export default styles;