import React, {
  useState,
  useEffect,
  useRef,
} from "react";

import {
  Box,
  Button,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import TimelineIcon from '@material-ui/icons/Timeline';

import Loading from "../../../components/Loading";
import Header from "../../../components/Header";
import api from "../../../services/api";
import Footer from "../../../components/Footer";
import SnackBar from "../../../util/SnackBar";

import FilterDate from '../../../components/FilterDate'

import {
  formatFirstDayCurrentMonth,
  formatCurrentDay
} from '../../../util/UtilDates';

import ReactExport from "react-export-excel";

import style from "./styles";

const styles = (theme) => style(theme);

function DashboardComparisonStock({
  classes
}) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [comparisonStock, setComparisonStock] = useState([]);
  const [dateInitial, setDateInitial] = useState(formatFirstDayCurrentMonth());
  const [dateFinal, setDateFinal] = useState(formatCurrentDay());
  const dateInitialFieldReference = useRef(null);
  const dateFinalFieldReference = useRef(null);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const matchMinW640 = useMediaQuery('(min-width:640px)');

  useEffect(() => {
    async function getComparisonStock() {
      try {
        setLoading(true);
        const response = await api.get(`/dashboard/comparison-stock?dateInitial=${dateInitial}&dateFinal=${dateFinal}`)
          .then((response) => response.data);

        setComparisonStock(response);
      } catch (err) {
        SnackBar.error("Falha ao carregar informações do Dashboard.");
      } finally {
        setLoading(false);
      }
    }
    getComparisonStock()
  }, [dateInitial, dateFinal]);

  const onClickFilterDate = () => {
    setDateInitial(dateInitialFieldReference.current.value);
    setDateFinal(dateFinalFieldReference.current.value);
  }

  const columns = [
    {
      id: "estoqueSoma",
      label: "Estoque Soma",
      align: "center",
    },
    {
      id: "estoqueWise",
      label: "Estoque Wise",
      align: "center",
    },
    {
      id: "material",
      label: "Material",
      align: "center",
    },
    {
      id: "filial",
      label: "Filial",
      align: "center",
    },
    {
      id: "corMaterial",
      label: "Cor material",
      align: "center",
    },
    {
      id: "ultimaEntrada",
      label: "Ultima Entrada",
      align: "center",
    },
    {
      id: "ultimaSaida",
      label: "Ultima Saida",
      align: "center",
    },
    {
      id: "es1",
      label: "ES1",
      align: "center",
    },
    {
      id: "produto",
      label: "Produto",
      align: "center",
    },
    {
      id: "corProduto",
      label: "Cor Produto",
      align: "center",
    },
  ];

  return (
    <>
      {loading ? (
        <>
          <Header />
          <Loading />
        </>
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100vh"
          >
            <Box
              display="flex"
              flexDirection="column"
            >
              <Header />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
            >
              <Box className={classes.boxStyle}>
                <span className={classes.title}>
                  <TimelineIcon
                    fontSize="large"
                    className={classes.iconStyle}
                  />
                  Comparação de Estoque
                </span>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                margin="10px"
              >
                <Paper className={classes.root}>
                  <TableContainer>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="space-between"
                      alignItems="center"
                      style={matchMinW640 ? {} : { justifyContent: 'center' }}
                    >
                      <InputBase className={classes.inputStyle}
                        placeholder="Procurar por material "
                        value={search}
                        onChange={(ev) => setSearch(ev.target.value)}
                      />
                      <ExcelFile element={
                        <Button variant="contained" className={classes.exportButton}>
                          Exportar para excel
                        </Button>
                      }>
                        <ExcelSheet data={
                          comparisonStock.filter((material) => {
                            if (
                              (material["material"].toLowerCase().includes(search.toLowerCase()))
                            ) {
                              return true;
                            }
                            return false;
                          })
                        } name="Histórico">
                          {columns.map((column) => (
                            <ExcelColumn
                              key={column.id} label={column.label} value={column.id}
                            />
                          ))}
                        </ExcelSheet>
                      </ExcelFile>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <FilterDate
                          dateInitial={dateInitial}
                          dateFinal={dateFinal}
                          onClick={onClickFilterDate}
                          inputReferenceInitial={dateInitialFieldReference}
                          inputReferenceFinal={dateFinalFieldReference}
                        />
                      </Box>
                    </Box>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              style={{ width: 300 }}
                              className={classes.cellHeader}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {comparisonStock.filter(material => material['material'].toLowerCase().includes(search.toLowerCase())).map((row, index) => {
                          return (
                            <TableRow hover key={index}>
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell key={index + column.id} align={column.align}>
                                    {value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </Box>
            <Footer />
          </Box>
        </>
      )}
    </>
  );
}

const wrapperComponent = withStyles(styles)(DashboardComparisonStock);
export default wrapperComponent;