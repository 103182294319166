const styles = (theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.3em',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  subgroupStyle: {
    overflow: 'auto',
    maxHeight: '36vh',
    backgroundColor: '#F8F8F8',
    borderRadius: 8,
  },
  titleBox: {
    fontWeight: 'bold',
    color: '#707070',
    marginBottom: 5,
    fontSize: 18,
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    color: "#AD9078",
    fontSize: "4vh",
    marginBottom: "30px",
  },
  buttonStyle: {
    marginTop: 10,
    borderRadius: 12,
    color: '#F8F8F8',
    // color: '#434645',
    // fontWeight: 'bold',
    fontSize: 14,
    backgroundColor: '#AD9078',
    '&:hover': {
      backgroundColor: '#A9845F',
    },
  },
  inputStyle: {
    width: '35vh',
    "& .MuiFilledInput-root": {
      borderRadius: 8,
      color: "#707070",
      backgroundColor: '#F8F8F8',
    },
    "& .MuiFormLabel-root": {
      color: "#aaaaaa",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiFilledInput-underline:before": {
      borderBottom: 0,
    },
  },
});
export default styles;