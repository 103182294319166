import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import style from "./styles";
import api from "../../../services/api";
import SnackBar from "../../../util/SnackBar";
import PreOrderValidationUpdate from "../PreOrderValidationUpdate";
import PreOrderUpdateSupplier from "../PreOrderUpdateSupplier";
import ConfirmDelete from "../../../components/ConfirmDelete";

const styles = (theme) => style(theme);

function PreOrderValidationDetails({ classes, orderId, supplier }) {
  const [preOrderDetails, setPreOrderDetails] = useState([]);
  const [reload, setReload] = useState();

  const [material, setMaterial] = useState("");
  const [materialColor, setMaterialColor] = useState("");
  const [materialDescription, setMaterialDescription] = useState("");
  const [originalAmount, setOriginalAmount] = useState("");
  const [cost, setCost] = useState("");
  const [orderUnitConversionFactor, setOrderUnitConversionFactor] = useState("");
  const [orderUnit, setOrderUnit] = useState("");
  const [paymentDays, setPaymentDays] = useState("");
  const [shippingCompany, setShippingCompany] = useState("");
  const [discount, setDiscount] = useState("");

  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState();
  const [openSupplier, setOpenSupplier] = useState();
  const [rowInfo, setRowInfo] = useState(false);

  const openUpdateModal = (
    material,
    materialColor,
    materialDescription,
    originalAmount,
    cost,
    orderUnitConversionFactor,
    orderUnit,
    paymentDays,
    shippingCompany,
    discount
  ) => {
    setMaterial(material);
    setMaterialColor(materialColor);
    setMaterialDescription(materialDescription);
    setOriginalAmount(originalAmount);
    setCost(cost);
    setOrderUnitConversionFactor(orderUnitConversionFactor);
    setOrderUnit(orderUnit);
    setPaymentDays(paymentDays);
    setShippingCompany(shippingCompany);
    setDiscount(discount);
    setOpenUpdate(true);
  };

  const closeUpdateModal = () => setOpenUpdate(false);

  const openDeleteModal = () => setOpenDelete(true);

  const closeDeleteModal = () => setOpenDelete(false);

  const openUpdateSupplier = () => setOpenSupplier(true);

  const closeUpdateSupplier = () => setOpenSupplier(false);

  useEffect(() => {
    const preOrderDetails = async (orderId) => {
      try {
        const response = await api
          .post("/purchases/pre-order-details", {
            orderId: `${orderId}`,
          })
          .then((response) => response.data);
        setPreOrderDetails(response);
      } catch (err) {
        SnackBar.error("Falha ao carregar informações do pedido.");
      }
    };
    preOrderDetails(orderId);
  }, [orderId, reload]);

  const handleDelete = async (orderId, material, materialColor) => {
    try {
      await api.delete("/purchases/delete-material", {
        data: { moreShoppingOrderId: orderId, material, materialColor },
      });
      SnackBar.success("Material excluído com sucesso!");
      setReload(!reload);
    } catch (err) {
      SnackBar.error("Falha ao excluir material");
    }
  };

  const columns = [
    {
      id: "material",
      label: "Material",
      align: "center",
    },
    {
      id: "materialColor",
      label: "Cor Material",
      align: "center",
    },
    {
      id: "materialDescription",
      label: "Descrição Material",
      align: "center",
    },
    {
      id: "originalAmount",
      label: "Quantidade Original",
      align: "center",
    },
    {
      id: "cost",
      label: "Custo",
      align: "center",
    },
    {
      id: "orderUnitConversionFactor",
      label: "Fator Conversão Unidade Pedido",
      align: "center",
    },
    {
      id: "orderUnit",
      label: "Unidade Pedido",
      align: "center",
    },
    {
      id: "supplierUnitConversionFactor",
      label: "Unidade Conversão Fornecedor",
      align: "center",
    },
    {
      id: "supplierUnit",
      label: "Unidade Fornecedor",
      align: "center",
    },
    {
      id: "paymentDays",
      label: "Condição pagamento",
      align: "center",
    },
    {
      id: "shippingCompany",
      label: "Transportadora",
      align: "center",
    },
    {
      id: "discount",
      label: "Desconto",
      align: "center",
    },
  ];

  return (
    <>
      <Box align="center">
        <Dialog onClose={closeUpdateModal} open={openUpdate} maxWidth={"xl"}>
          <DialogContent dividers>
            <PreOrderValidationUpdate
              orderId={orderId}
              material={material}
              materialColor={materialColor}
              materialDescription={materialDescription}
              originalAmount={originalAmount}
              cost={cost}
              orderUnitConversionFactor={orderUnitConversionFactor}
              orderUnit={orderUnit}
              paymentDays={paymentDays}
              shippingCompany={shippingCompany}
              discount={discount}
              onClickClose={() => closeUpdateModal()}
              onReload={() => setReload(!reload)}
            />
          </DialogContent>
        </Dialog>
        <Dialog onClose={closeDeleteModal} open={openDelete}>
          <DialogContent dividers>
            <ConfirmDelete
              onClickCancel={() => closeDeleteModal()}
              onClickConfirm={() => {
                handleDelete(
                  rowInfo["orderId"],
                  rowInfo["material"],
                  rowInfo["materialColor"],
                  closeDeleteModal()
                );
              }}
              message="Deseja cancelar este pedido?"
            />
          </DialogContent>
        </Dialog>
        <Dialog onClose={closeUpdateSupplier} open={openSupplier} maxWidth={"xl"}>
          <DialogContent dividers>
            <PreOrderUpdateSupplier
              orderId={orderId}
              supplier={supplier}
              material={material}
              onClickClose={() => closeUpdateSupplier()}
              onReload={() => setReload(!reload)}
            />
          </DialogContent>
        </Dialog>
        <Box className={classes.boxTitulo}>
          <span className={classes.title}>PEDIDO: {orderId}</span>
          <Button
            variant="contained"
            className={classes.orderButton}
            onClick={() => openUpdateSupplier()}
          >
            Alterar fornecedor
          </Button>
        </Box>
        <Paper>
          <TableContainer>
            <Table className={classes.lista}>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ maxWidth: 300 }}
                    className={classes.cellHeader}
                    align="center"
                  >
                    Editar
                  </TableCell>
                  <TableCell
                    style={{ maxWidth: 300 }}
                    className={classes.cellHeader}
                    align="center"
                  >
                    Excluir
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{ width: 300 }}
                      className={classes.cellHeader}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {preOrderDetails.map((row, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell align="center">
                        <IconButton
                          onClick={() =>
                            openUpdateModal(
                              row["material"],
                              row["materialColor"],
                              row["materialDescription"],
                              row["originalAmount"],
                              row["cost"],
                              row["orderUnitConversionFactor"],
                              row["orderUnit"],
                              row["paymentDays"],
                              row["shippingCompany"],
                              row["discount"]
                            )
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => {
                            openDeleteModal();
                            setRowInfo(row);
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </TableCell>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={index + column.id}
                            align={column.align}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}

const wrapperComponent = withStyles(styles)(PreOrderValidationDetails);
export default wrapperComponent;
