import React from 'react';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from './util/SnackBar';
import { Router } from 'react-router-dom';
import Routes from './routes';
import history from './services/history';
import GlobalStyle from './styles/global';
import { UserContextProvider } from './hooks/UserContextProvider';

function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={3500}
    >
      <SnackbarUtilsConfigurator />
      <UserContextProvider>
        <Router history={history}>
          <Routes />
          <GlobalStyle />
        </Router>
      </UserContextProvider>
    </SnackbarProvider>
  );
}

export default App;
