const styles = theme => ({
  container: {
    height: 60,
    backgroundColor: '#F3F3F3',
  },
  logoSoma: {
    height: 50,
    width: 'auto',
    margin: '0 auto',
  },
  homeIcon: {
    color: '#494D4B',
    margin: theme.spacing(1),
  },
});

export default styles;