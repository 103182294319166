import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow
} from "@material-ui/core";

import SnackBar from "../../../util/SnackBar";

import style from "./styles";
  
const styles = (theme) => style(theme);

function RestockOrderError({
  classes,
  material,
  msg,
}) {
  SnackBar.error("Falha ao criar pré-pedido.");

  const row = material;

  const columns = [
    {
      id: 'material',
      label: 'Material',
      align: 'center',
    },
    {
      id: 'msgReturn',
      label: 'Mensagem de Retorno',
      align: 'center',
    }
  ];

  return (
    <>
      <Box align="center">
        <Box className={classes.boxTitulo}>
          <span className={classes.title}>Falha na criação dos seguintes materiais:</span>
        </Box>
        <Paper>
          <TableContainer>
          <Table className={classes.lista}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ width: 300 }}
                    className={classes.cellHeader}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {row.map((row, index) => {
                return(
                <TableRow hover>
                  <TableCell
                    key={index.id}
                    style={{ width: 300 }}
                    className={classes.cellHeader}
                  >
                    {material[index]}
                  </TableCell>
                  <TableCell
                    key={index.id}
                    style={{ width: 300 }}
                    className={classes.cellHeader}
                  >
                    {msg[index]}
                  </TableCell>
                </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        </Paper>
        <Box
          marginTop="5px"
          align="right"
        >
          <span className={classes.obs}>*</span> Os demais materiais foram criados!
        </Box>
      </Box>
    </>
  );
}

const wrapperComponent = withStyles(styles)(RestockOrderError);
export default wrapperComponent;