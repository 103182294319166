import React, { 
  useState, 
  useEffect, 
} from "react";
import { 
  Box, 
  Button, 
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InputBase from "@material-ui/core/InputBase";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Checkbox from "@material-ui/core/Checkbox";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import style from "./styles";
import Loading from "../../components/Loading";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import api from "../../services/api";
import SnackBar from "../../util/SnackBar";
import ConfirmDelete from "../../components/ConfirmDelete";
import RestockOrderError from "./RestockOrderError";

const styles = (theme) => style(theme);

function Restock({ 
  classes, 
}) {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [ordersAux, setOrdersAux] = useState([]);
  const [search, setSearch] = useState('');
  const [reload, setReload] = useState(false);
  const [statusFilter, setStatusFilter] = useState([]);
  const [checked, setChecked] = useState([]);
  const [arrayIndex, setArrayIndex] = useState([]);
  const [openConfirm, setOpenConfirm] = useState();
  const [openDetails, setOpenDetails] = useState(false);
  const [isChecked, setIsChecked] = useState({});
  const [material, setMaterial] = useState([]);
  const [msg, setMsg] = useState([]);
  const [isStatus1, setIsStatus1] = useState(false);
  const [isStatus2, setIsStatus2] = useState(false);
  const [isStatus3, setIsStatus3] = useState(false);
  const [isStatus4, setIsStatus4] = useState(false);

  const matchMinW640 = useMediaQuery('(min-width:640px)');

  const refresh = () => window.location.reload();

  useEffect(() => {
    async function restock() {
      try {
        setLoading(true);
        const response = await api
          .get("/restock/necessity")
          .then((response) => response.data);
        setOrders(response);
        setOrdersAux(response);
      } catch (err) {
        SnackBar.error("Falha ao carregar informações do estoque.");
      } finally {
        setLoading(false);
      }
    }
    restock();
    setReload();
  }, [reload]);

  useEffect(() => {
    setOrdersAux(orders);
  }, [orders, isChecked])

  const handleToggle = (row) => () => {
    const currentIndex = arrayIndex.indexOf(row.id);
    const newChecked = checked;
    const newIsChecked = {...isChecked};
    if (currentIndex === -1) {
      newChecked.push(row.material, row.purchaseSuggestion, row.materialColor);
      arrayIndex.push(row.id);
      newIsChecked[row.id] = true;
    } else {
      newChecked.splice(currentIndex, 3);
      arrayIndex.splice(currentIndex, 1);
      delete newIsChecked[row.id];
    }
    setChecked(newChecked);
    setArrayIndex(arrayIndex);
    setIsChecked(newIsChecked);
  };

  const clearChecked = () => {
    setArrayIndex([]);
    setIsChecked({});
    setChecked([]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let ordersParams = [];
    while (checked.length) {
      ordersParams.push(checked.splice(0, 3));
    }
    let response;
    try {
      response = await api
      .post("/purchases/multiple-pre-orders", {
        checked: `${ordersParams}`,
      })
      .then((response) => response.data);
      const res = response.msgReturn;
      let dataArray = [];
      res.map((datas) => {
        if (datas.status === "Erro") {
          dataArray.push(datas);
          dataArray.map((item) => {
            material.push(item.material);
            msg.push(item.msg);
            return (material, msg)
          });
          return new Promise(() => {
            setTimeout(() => {
              openDetailsModal(material, msg);
              setLoading(false);
            }, 2000);
          });
        }
        return res;
      });

      const orderOk = res.some((item) => {
        return item.status !== "Erro";
      });

      if (orderOk) {
        SnackBar.success(`Pré-pedido(s) criado(s) com sucesso.`);
        if (dataArray.length < 1) {
          refresh();
        }
      }
    } catch (err) {
      SnackBar.error("Falha ao criar pré-pedido.");
    }
  };

  const profileStatusColor = (status) => {
    if (status === "1") {
      // amarelo - existe pedido criado
      return "#F6BD60";
    } else if (status === "2") {
      // azul - aguardando pedido ser entregue
      return "#ABD7F5";
    } else if (status === "3") {
      // cinza - não há sugestão de compra
      return "#E7E7E7";
    } else if (status === "4") {
      // vermelho - sugestão de compra
      return "#EF6E6B";
    }
  };

  const updateStatusFilter = (status) => {
    if (statusFilter.includes(status)) {
      if(status === "1"){
        setIsStatus1((current) => !current);
        setStatusFilter(statusFilter.filter((stat) => stat !== status));
        return;
      }
      else if(status === "2"){
        setIsStatus2((current) => !current);
        setStatusFilter(statusFilter.filter((stat) => stat !== status));
        return;
      }
      else if(status === "3"){
        setIsStatus3((current) => !current);
        setStatusFilter(statusFilter.filter((stat) => stat !== status));
        return;
      }
      else{
        setIsStatus4((current) => !current);
        setStatusFilter(statusFilter.filter((stat) => stat !== status));
        return;
      }
    }
    else if (status === "1"){
      setIsStatus1((current) => !current);
      setStatusFilter([...statusFilter, status]);
    }
    else if (status === "2"){
      setIsStatus2((current) => !current);
      setStatusFilter([...statusFilter, status]);
    }
    else if (status === "3"){
      setIsStatus3((current) => !current);
      setStatusFilter([...statusFilter, status]);
    }
    else{
      setIsStatus4((current) => !current);
      setStatusFilter([...statusFilter, status]);
    }
  };

  const openConfirmModal = () => setOpenConfirm(true);

  const closeConfirmModal = () => setOpenConfirm(false);

  const openDetailsModal = (material, msg) => {
    setMaterial(material);
    setMsg(msg);
    setOpenDetails(true);
  };

  const closeDetailsModal = () => {
    setOpenDetails(false);
    setIsChecked(!isChecked);
    refresh();
  };

  const tableLeft = [
    {
      id: "supplier",
      label: "Fornecedor",
      align: "center",
    },
    {
      id: "materialDescription",
      label: "Descrição Material",
      align: "center",
    },
  ];

  const tableRight = [
    {
      id: "materialColor",
      label: "Cor",
      align: "center",
    },
    {
      id: "material",
      label: "Material",
      align: "center",
    },
    {
      id: "stockAmount",
      label: "Estoque",
      align: "center",
    },
    {
      id: "pickingAmount90Days",
      label: "Picking 90 dias",
      align: "center",
    },
    {
      id: "deliveryAmount",
      label: "A entregar",
      align: "center",
    },
    {
      id: "stockDurationDays",
      label: "Cobertura",
      align: "center",
    },
    {
      id: "purchaseSuggestion",
      label: "Sugestao de compra",
      align: "center",
    },
    {
      id: "statusItem",
      label: "Status",
      align: "center",
    },
    {
      id: "consumption",
      label: "Consumo diário",
      align: "center",
    },
    {
      id: "deliveryDays",
      label: "Lead Time",
      align: "center",
    },
    {
      id: "maximumStockDays",
      label: "Dias Est. Máx",
      align: "center",
    },
    {
      id: "maximumStock",
      label: "Estoque Máximo",
      align: "center",
    },
    {
      id: "minimumStockDays",
      label: "Dias Est. Mín",
      align: "center",
    },
    {
      id: "minimumStock",
      label: "Estoque mínimo",
      align: "center",
    },
    {
      id: "observation",
      label: "Observação",
      align: "center",
    },
    {
      id: "unr",
      label: "UNR",
      align: "center",
    },
    {
      id: "brand",
      label: "Marca",
      align: "center",
    },
    {
      id: "subgroup",
      label: "Segmento",
      align: "center",
    },
    {
      id: "branch",
      label: "Filial",
      align: "center",
    },
    {
      id: "isCustom",
      label: "Personalizado",
      align: "center",
    },
    {
      id: "isEcommerce",
      label: "E-commerce",
      align: "center",
    },
  ];

  return (
    <>
      {loading ? (
        <>
          <Header />
          <Loading />
        </>
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100vh"
          >
            <Box display="flex" flexDirection="column">
              <Header />
            </Box>
            <Dialog onClose={closeConfirmModal} open={openConfirm}>
              <DialogContent dividers>
                <ConfirmDelete
                  onClickCancel={() => closeConfirmModal()}
                  onClickConfirm={() => handleSubmit(closeConfirmModal())}
                  message="Deseja criar estes pedidos?"
                />
              </DialogContent>
            </Dialog>
            <Dialog onClose={closeDetailsModal} open={openDetails} maxWidth={"xl"}>
              <DialogContent dividers>
                <RestockOrderError
                  material={material}
                  msg={msg}
                  onClickClose={() => closeDetailsModal()}
                  onReload={() => setReload(!reload)}
                />
              </DialogContent>
            </Dialog>
            <Box display="flex" flexDirection="column" flex={1}>
              <Box className={classes.boxStyle}>
                <span className={classes.title}>
                  <ShowChartIcon
                    fontSize="large"
                    className={classes.iconStyle}
                  />
                  Necessidade de Reposição
                </span>
                <Button
                  variant="contained"
                  className={classes.orderButton}
                  onClick={() => openConfirmModal()}
                >
                  Criar pedidos
                </Button>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                className={classes.container}
              >
                <Paper className={classes.root}>
                  <TableContainer>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.boxFixed}
                      style={matchMinW640 ? {} : { justifyContent: "center" }}
                    >
                      <InputBase
                        className={classes.inputStyle}
                        placeholder="Procurar por Desc Material ou Fornecedor"
                        value={search}
                        onChange={(ev) => setSearch(ev.target.value)}
                      />
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        style={matchMinW640 ? {} : { justifyContent: "center" }}
                      >
                        <Button
                          className={classes.statusStyle}
                          onClick={() => updateStatusFilter("1")}
                          style={{
                            backgroundColor: isStatus1 ? "#888888" : "",
                            color: isStatus1 ? "white" : ""
                          }}
                          
                        >
                          <FiberManualRecordIcon
                            style={{
                              color: "#F6BD60",
                              verticalAlign: "middle",
                            }}
                          />
                          Pré-pedido criado
                        </Button>
                        <Button
                          className={classes.statusStyle}
                          onClick={() => updateStatusFilter("2")}
                          style={{
                            backgroundColor: isStatus2 ? "#888888" : "",
                            color: isStatus2 ? "white" : ""
                          }}
                          
                        >
                          <FiberManualRecordIcon
                            style={{
                              color: "#ABD7F5",
                              verticalAlign: "middle",
                            }}
                          />
                          Aguardando entrega
                        </Button>
                        <Button
                          className={classes.statusStyle}
                          onClick={() => updateStatusFilter("3")}
                          style={{
                            backgroundColor: isStatus3 ? "#888888" : "",
                            color: isStatus3 ? "white" : ""
                          }}
                          
                        >
                          <FiberManualRecordIcon
                            style={{
                              color: "#E7E7E7",
                              verticalAlign: "middle",
                            }}
                          />
                          Estoque regular
                        </Button>
                        <Button
                          className={classes.statusStyle}
                          onClick={() => updateStatusFilter("4")}
                          style={{
                            backgroundColor: isStatus4 ? "#888888" : "",
                            color: isStatus4 ? "white" : ""
                          }}
                          
                        >
                          <FiberManualRecordIcon
                            style={{
                              color: "#EF6E6B",
                              verticalAlign: "middle",
                            }}
                          />
                          Necessidade de compra
                        </Button>
                      </Box>
                    </Box>
                    {matchMinW640 ? (
                      <>
                        <Table stickyHeader >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                className={ classes.headerFixed}
                              >
                                <TableCell
                                  style={{ width: 30 }}
                                  className={classes.cellHeader}
                                >
                                  Limpar
                                  <Checkbox
                                    checked
                                    onClick={clearChecked}
                                  />
                                </TableCell>
                                <TableCell
                                  style={{ width: 30 }}
                                  className={classes.cellHeader}
                                >
                                  Status
                                </TableCell>
                                {tableLeft.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    className={classes.cellHeader}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableCell>
                              {tableRight.map((column) => (
                                <TableCell
                                  key={column.id}
                                  style={{ minWidth: column.minWidth}}
                                  className={classes.cellHeader}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {ordersAux
                              .filter((order) => {
                                if (
                                  (order["materialDescription"]
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                    order["supplier"]
                                      .toLowerCase()
                                      .includes(search.toLowerCase())) &&
                                  (statusFilter.includes(order.status) ||
                                    statusFilter.length === 0)
                                ) {
                                  return true;
                                }
                                return false;
                              })
                              .map((row, index) => {
                                return (
                                  <TableRow key={row.id}>
                                    <Table
                                      className={classes.boxFixed}
                                    >
                                      <TableCell align="center" key={row.id}>
                                        <Checkbox
                                          disabled={(row.status === '1' || row.status === '2') ? true : false}
                                          checked={isChecked[row.id] ? true : false}
                                          onClick={handleToggle(row)}
                                        />
                                      </TableCell>
                                      <TableCell align="center">
                                        <FiberManualRecordIcon
                                          fontSize="large"
                                          style={{
                                            color: profileStatusColor(row.status),
                                            verticalAlign: "middle",
                                          }}
                                        />
                                      </TableCell>
                                      {tableLeft.map((column) => {
                                        const value = row[column.id];
                                        return (
                                          <TableCell
                                            key={index + column.id}
                                            align={column.align}
                                          >
                                            {value}
                                          </TableCell>
                                        );
                                      })}
                                    </Table>
                                    {tableRight.map((column) => {
                                      const value = row[column.id];
                                      return (
                                        <TableCell
                                          key={index + column.id}
                                          align={column.align}
                                        >
                                          {value}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                              <TableRow>
                                <Table>
                                  <TableCell className={classes.cellBody}> </TableCell>
                                  <TableCell className={classes.cellBody}> </TableCell>
                                  <TableCell className={classes.cellBody}> </TableCell>
                                  <TableCell className={classes.cellBody}> </TableCell>                                     
                                </Table>
                              </TableRow>
                          </TableBody>
                        </Table>
                      </> 
                    ) : (
                    <> 
                      <Table>
                        <TableHead>
                          <TableRow>
                            <Table>
                              <TableCell
                                style={{ width: 30 }}
                                className={classes.cellHeader}
                              >
                                Limpar
                                <Checkbox
                                  checked
                                  onClick={clearChecked}
                                />
                              </TableCell>
                              <TableCell
                                style={{ width: 30 }}
                                className={classes.cellHeader}
                              >
                                Status
                              </TableCell>
                              {tableLeft.map((column) => (
                                <TableCell
                                  key={column.id}
                                  className={classes.cellHeader}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </Table>
                            {tableRight.map((column) => (
                              <TableCell
                                key={column.id}
                                style={{ minWidth: column.minWidth}}
                                className={classes.cellHeader}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ordersAux
                            .filter((order) => {
                              if (
                                (order["materialDescription"]
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ||
                                  order["supplier"]
                                    .toLowerCase()
                                    .includes(search.toLowerCase())) &&
                                (statusFilter.includes(order.status) ||
                                  statusFilter.length === 0)
                              ) {
                                return true;
                              }
                              return false;
                            })
                            .map((row, index) => {
                              return (
                                <TableRow key={row.id}>
                                  <Table>
                                    <TableCell align="center" key={row.id}>
                                      <Checkbox
                                        disabled={(row.status === '1' || row.status === '2') ? true : false}
                                        checked={isChecked[row.id] ? true : false}
                                        onClick={handleToggle(row)}
                                      />
                                    </TableCell>
                                    <TableCell align="center">
                                      <FiberManualRecordIcon
                                        fontSize="large"
                                        style={{
                                          color: profileStatusColor(row.status),
                                          verticalAlign: "middle",
                                        }}
                                      />
                                    </TableCell>
                                    {tableLeft.map((column) => {
                                      const value = row[column.id];
                                      return (
                                        <TableCell
                                          key={index + column.id}
                                          align={column.align}
                                        >
                                          {value}
                                        </TableCell>
                                      );
                                    })}
                                  </Table>
                                  {tableRight.map((column) => {
                                    const value = row[column.id];
                                    return (
                                      <TableCell
                                        key={index + column.id}
                                        align={column.align}
                                      >
                                        {value}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </>
                    )}
                  </TableContainer>
                </Paper>
              </Box>
            </Box>
            <Footer />
          </Box>
        </>
      )}
    </>
  );
}

const wrapperComponent = withStyles(styles)(Restock);
export default wrapperComponent;