import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { 
  CircularProgress, 
  Grid, 
} from '@material-ui/core';

import style from './styles';

const styles = theme => style(theme);

function Loading({
  classes,
}) {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.container}
    >
      <CircularProgress size={50} />
    </Grid>
  );
}

const wrapperComponent = withStyles(styles)(Loading);
export default wrapperComponent;