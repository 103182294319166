import React, {useState, useEffect} from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Button, Box, Typography } from "@material-ui/core";

import style from "./styles";

const styles = (theme) => style(theme);

function FilterDate({
  classes,
  onClick,
  dateInitial,
  dateFinal,
  inputReferenceInitial,
  inputReferenceFinal,
}) {

  const [filterMessage, setFilterMessage] = useState('');
    
  const  initialDate = dateInitial ? {
    year: dateInitial.substring(0, 4),
    month: dateInitial.substring(5, 7),
    day: dateInitial.substring(10, 8)          
  } : null;

  const initialDateFormat = initialDate ? (initialDate.day + '/' + initialDate.month + '/' + initialDate.year) : null;    

  const  finalDate = dateFinal ? {
    year: dateFinal.substring(0, 4),
    month: dateFinal.substring(5, 7),
    day: dateFinal.substring(10, 8)
  } : null;
    
  const finalDateFormat = finalDate ? (finalDate.day + '/' + finalDate.month + '/' + finalDate.year) : null;

  useEffect(() => {
    if (initialDateFormat) {
      setFilterMessage(`Insira uma data final valida`);
    } else if (finalDateFormat) {
      setFilterMessage(`Filtro de data aplicado de: data inicial invalida até ${finalDateFormat} `);
    } else {        
      setFilterMessage('Data inicial e Data final invalidas');
    }
  }, [initialDateFormat, finalDateFormat]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexWrap="wrap"
      alignItems="center"
    >
      <Box 
        container 
        justify="center"
      >
        <Box 
          container 
          justify="center" 
          flexWrap="wrap"
          display="flex" 
          justifyContent="center"
        >
          <TextField
            id="dateInitial"
            label="Data inicial"
            type="date"
            defaultValue={dateInitial}
            inputRef={inputReferenceInitial}
            className={classes.dateField}
            InputLabelProps={{
              shrink: true,
            }}
            />
          <TextField
            id="dateFinal"
            label="Data final"
            type="date"
            defaultValue={dateFinal}
            inputRef={inputReferenceFinal}
            className={classes.dateField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </Box>
      <Button
        className={classes.flexButton}
        onClick={onClick}
        variant="contained"
      >
        Filtrar
      </Button>
      {initialDateFormat && finalDateFormat ? (
        <>

        </>
      ) : (
        <>
          <Typography variant="body2" component="p" className={classes.filterText} >
            {filterMessage}
          </Typography>
        </>
      )}
    </Box>
  );
}

const wrapperComponent = withStyles(styles)(FilterDate);

export default wrapperComponent;
