import axios from 'axios';

const api = axios.create({
  baseURL: 'https://mais-compras-backend-ysxas3xhea-uc.a.run.app',
  transformRequest: [
    (data, headers) => {
      const token = localStorage.getItem("+compras:token");
      
      if (token) {
        headers.common.Authorization = `Bearer ${token}`;
      }

      return data;
    },
    ...axios.defaults.transformRequest,
  ],
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (!error.response) return error;

    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem('+compras:token');

      if (window.location.pathname !== '/login') {
        window.location = '/login';
      }
      return error;
    }
    
    return Promise.reject(error);
  }
);

export default api;