const styles = theme => ({
  root: {
    height: 200,
    width: 200,
    margin: '10px',
  },
  boxIcon: {
    marginTop: theme.spacing(2),
  },
  buttonBase: {
    height: '100%',
  },
});

export default styles;