const styles = (theme) => ({
  title: {
    marginBottom: 20,
    fontSize: 16,
    color: "#707070",
  },
  confirmStyle: {
    borderRadius: 12,
    fontSize: 16,
    color: '#F3F3F3',
    boxShadow: 'none',
    backgroundColor: '#AD9078',
    '&:hover': {
      backgroundColor: '#A9845F',
      boxShadow: 'none',
    },
  },
  cancelStyle: {
    borderRadius: 12,
    fontSize: 16,
    color: '#707070',
    boxShadow: 'none',
    backgroundColor: '#E7E7E7',
    '&:hover': {
      backgroundColor: '#EDEDED',
      boxShadow: 'none',
    },
  },
})
export default styles;