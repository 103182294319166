import React, { 
  useState, 
  useEffect, 
  useRef,
} from "react";
  
import { 
  Box, 
  Button,
  InputBase, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  useMediaQuery,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import TimelineIcon from '@material-ui/icons/Timeline';
  
import Loading from "../../../components/Loading";
import Header from "../../../components/Header";
import api from "../../../services/api";
import Footer from "../../../components/Footer";
import SnackBar from "../../../util/SnackBar";
  
import FilterDate from '../../../components/FilterDate'
  
import {
  formatFirstDayCurrentMonth,
  formatCurrentDay
} from '../../../util/UtilDates';

import ReactExport from "react-export-excel";
  
import style from "./styles";
  
const styles = (theme) => style(theme);

function DashboardOrdersComparison({
  classes,
}) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [ordersComparison, setOrderComparison] = useState([]);
  const [dateInitial, setDateInitial] = useState(formatFirstDayCurrentMonth());
  const [dateFinal, setDateFinal] = useState(formatCurrentDay());
  const dateInitialFieldReference = useRef(null);
  const dateFinalFieldReference = useRef(null);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const matchMinW640 = useMediaQuery('(min-width:640px)');

  useEffect(() => {
    async function getOrderComparison(){
      try {
        setLoading(true);
        const response = await api.get(`/dashboard/orders-comparison?dateInitial=${dateInitial}&dateFinal=${dateFinal}`)
          .then((response) => response.data);

        setOrderComparison(response);
        } catch(err) {
          SnackBar.error("Falha ao carregar informações do Dashboard.");

        } finally {
          setLoading(false);
        }
      }
      getOrderComparison();
      
    }, [dateInitial, dateFinal]);

  const onClickFilterDate = () => {
    setDateInitial(dateInitialFieldReference.current.value);
    setDateFinal(dateFinalFieldReference.current.value);
  }

  const columns = [
    {
      id: "canal",
      label: "Canal",
      align: "center",
    },
    {
      id: "filial",
      label: "Filial",
      align: "center",
    },
    {
      id: "qtdePedidoCanal",
      label: "Quant. Pedido Canal",
      align: "center",
    },
    {
      id: "valorPedidoCanal",
      label: "Valor Pedido Canal",
      align: "center",
    },
    {
      id: "qtdePedidoLinx",
      label: "Quant. Pedido Linx",
      align: "center",
    },
    {
      id: "valorPedidoLinx",
      label: "Valor Pedido Linx",
      align: "center",
    },
  ];

  return(
    <>
      {loading ? (
        <>
          <Header />
          <Loading />
        </>  
      ) : (
        <>
          <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100vh"
          >
            <Box
              display="flex"
              flexDirection="column"
            >
              <Header />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
            >
              <Box className={classes.boxStyle}>
                <span className={classes.title}>
                  <TimelineIcon
                    fontSize="large"
                    className={classes.iconStyle}
                  />
                  Comparativo de Pedidos
                </span>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                margin="10px"
              >
                <Paper className={classes.root}>
                  <TableContainer>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="space-between"
                      alignItems="center"
                      style={matchMinW640 ? {} : { justifyContent: 'center' }}
                    >
                      <InputBase className={classes.inputStyle}
                        placeholder="Procurar por canal"
                        value={search}
                        onChange={(ev) => setSearch(ev.target.value)}
                      />
                      <ExcelFile element={
                        <Button variant="contained" className={classes.exportButton}>
                          Exportar para excel
                        </Button>
                      }>
                        <ExcelSheet data={
                          ordersComparison.filter((canal) => { 
                            if (
                              (canal["canal"].toLowerCase().includes(search.toLowerCase()))
                            ) {
                              return true;
                            }
                            return false;
                          })
                        } name="Histórico">
                          {columns.map((column) => (
                            <ExcelColumn
                              key={column.id} label={column.label} value={column.id}
                            />
                          ))}
                        </ExcelSheet>
                      </ExcelFile>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <FilterDate
                          dateInitial={dateInitial}
                          dateFinal={dateFinal}
                          onClick={onClickFilterDate}
                          inputReferenceInitial={dateInitialFieldReference}
                          inputReferenceFinal={dateFinalFieldReference}
                        />
                      </Box>
                    </Box>
                    <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                          {columns.map((column) =>(
                            <TableCell
                              key={column.id}
                              style={{ width: 300 }}
                              className={classes.cellHeader}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ordersComparison.filter(canal=> canal['canal'].toLowerCase().includes(search.toLowerCase())).map((row, index) => {
                          return (
                            <TableRow hover key={index}>
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell key={index + column.id} align={column.align}>
                                    {value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </Box>
            <Footer />
          </Box>
        </>
      )}
    </>
  );
}

  

const wrapperComponent = withStyles(styles)(DashboardOrdersComparison);
export default wrapperComponent;