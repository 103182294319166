import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
  ButtonBase,
} from '@material-ui/core'

import style from './styles';

const styles = theme => style(theme);

function CardButton({
  classes,
  children,
  content,
  onClick,
}) {
  return (
    <Card className={classes.root}>
      <ButtonBase
        onClick={onClick}
        className={classes.buttonBase}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <CardMedia>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
              className={classes.boxIcon}
            >
              {children}
            </Box>
          </CardMedia>
          <CardContent>
            <Grid
              container
              justify="center"
              alignItems="center"
            >
              <Typography gutterBottom variant="h5" component="h2">
                {content}
              </Typography>
            </Grid>
          </CardContent>
        </Box>
      </ButtonBase>
    </Card>
  );
}

const wrapperComponent = withStyles(styles)(CardButton);

export default wrapperComponent;