import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { 
  Grid, 
  CardMedia, 
  IconButton, 
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';

import logoCompras from '../../assets/logoCompras.png';
import history from '../../services/history';
import LeftMenu from '../LeftMenu';

import style from './styles';

const styles = theme => style(theme);

function Header({
  classes,
}) {
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.container}
      >
        <LeftMenu />
        <CardMedia
          component="img"
          src={logoCompras}
          title="Grupo Soma"
          className={classes.logoSoma}
        />
        <IconButton
          onClick={() => {
            history.push('/')
          }}
        >
          <HomeIcon
            className={classes.homeIcon}
          />
        </IconButton>
      </Grid>
    </>
  );
}

const wrapperComponent = withStyles(styles)(Header);
export default wrapperComponent;