// Funcao que adiciona um zero a String caso o valor dela seja menor que 10
function addZero(number) {
  const numberTransformed = parseInt(number, 10);

  if (numberTransformed <= 9)
    return "0" + String(numberTransformed);
  else
    return String(numberTransformed);
}

// Retorna o primeiro dia do mes atual no seguinte formato YYYY-MM-DD
const formatFirstDayCurrentMonth = () => {
  const currentDate = new Date();
  return (currentDate.getFullYear() + '-' + (addZero(currentDate.getMonth() + 1).toString()) + '-01');
}

// Retorna o dia atual no seguinte formato YYYY-MM-DD
const formatCurrentDay = () => {
  const currentDate = new Date();
  return (currentDate.getFullYear() + '-' + (addZero(currentDate.getMonth() + 1).toString()) + '-' + addZero(currentDate.getDate().toString()));
}

export { formatFirstDayCurrentMonth, formatCurrentDay };
