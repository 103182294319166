const styles = (theme) => ({
  footer: {
    color: '#707070',
    marginRight: 10,
    '& span': {
      fontWeight: 'bold'
    }
  },
})

export default styles;
