import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Grid from '@material-ui/core/Grid'

import Header from '../../components/Header';
import Footer from '../../components/Footer'
import CardButton from '../../components/CardButton';
import style from './styles';
import history from '../../services/history';
import { ROUTES } from '../../consts';

import { UserConsulta, PermissionsGate } from '../../hooks/UserContextProvider';

const styles = theme => style(theme);

function HomeCards ({
  classes,
}) {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100vh"
      >
        <Box display="flex" flexDirection="column">
          <Header />
        </Box>
        <Box display="flex" flexDirection="column" flex={1}>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
            className={classes.box}
          >
            { PermissionsGate() !== UserConsulta.EmailConsulta ? 
              (
                <>
                  <Grid container>
                    <Box className={classes.boxStyle}>
                      <span className={classes.title}> 
                        SISTEMA
                      </span>
                    </Box>
                    <Grid container className={classes.card}> 
                      <Grid item > 
                        <CardButton
                          content="Necessidade de reposição"
                          onClick={
                            (e) => {
                              history.push(ROUTES.RESTOCK);
                            }
                          }
                        >
                          <ShowChartIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Histórico de pedidos"
                          onClick={
                            (e) => {
                              history.push(ROUTES.ORDER_HISTORY);
                            }
                          }
                        >
                          <HistoryIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Criar pedido avulso"
                          onClick={
                            (e) => {
                              history.push(ROUTES.SINGLE_ORDER);
                            }
                          }
                        >
                          <AddShoppingCartIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Validação de pré-pedido"
                          onClick={
                            (e) => {
                              history.push(ROUTES.PREORDER_VALIDATION);
                            }
                          }
                        >
                          <LibraryAddCheckIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Administração de cargos"
                          onClick={
                            (e) => {
                              history.push(ROUTES.MATERIAL_RESPONSIBLE);
                            }
                          }
                        >
                          <PersonIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Estoque de reposição"
                          onClick={
                            (e) => {
                              history.push(ROUTES.MRPSTOCK);
                            }
                          }
                        >
                          <PlaylistAddIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      </Grid>
                      <Box className={classes.boxStyle}>
                        <span className={classes.title}> 
                          DASHBOARD
                        </span>
                      </Box>
                      <Grid container className={classes.card}>
                      <Grid item > 
                        <CardButton
                          content="Consumo por produto"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_PRODUCT_CONSUMPTION);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Consumo por categoria"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_CATEGORY_CONSUMPTION);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Curva de prazo de entrega"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_ITEMS_DEADLINE);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Top 10 Fornecedores"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_TOP_SUPPLIERS);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Curva de itens no CD"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_DISTRIBUTION_CENTER);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Giro de Estoque"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_STOCK_CONTROL);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Valores entrada por período"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_INCOMING_VALUES);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Quant. pedidos e valores"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_ORDERS_VALUES);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Consumo do subgrupo"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_SUBGROUP_CONSUMPTION);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Indicador de saúde por filial"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_HEALTH_INDICATOR);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Comparativo de pedidos"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_ORDERS_COMPARISON);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Base de Comsumo"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_CONSUMPTION_BASE);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Comparação de Estoque"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_COMPARISON_STOCK);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item > 
                    <Box className={classes.boxStyle}>
                      <span className={classes.title}> 
                        DASHBOARD
                      </span>
                    </Box>
                    <Grid container className={classes.card}>
                      <Grid item > 
                        <CardButton
                          content="Consumo por produto"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_PRODUCT_CONSUMPTION);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Consumo por categoria"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_CATEGORY_CONSUMPTION);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Curva de prazo de entrega"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_ITEMS_DEADLINE);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Top 10 Fornecedores"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_TOP_SUPPLIERS);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Curva de itens no CD"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_DISTRIBUTION_CENTER);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Giro de Estoque"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_STOCK_CONTROL);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Valores entrada por período"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_INCOMING_VALUES);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Quant. pedidos e valores"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_ORDERS_VALUES);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Consumo do subgrupo"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_SUBGROUP_CONSUMPTION);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Indicador de saúde por filial"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_HEALTH_INDICATOR);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Comparativo de pedidos"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_ORDERS_COMPARISON);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Base de Comsumo"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_CONSUMPTION_BASE);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                      <Grid item > 
                        <CardButton
                          content="Comparação de Estoque"
                          onClick={
                            (e) => {
                              history.push(ROUTES.DASHBOARD_COMPARISON_STOCK);
                            }
                          }
                        >
                          <DashboardIcon
                            fontSize="large"
                          />
                        </CardButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )
            }
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  )
}

const wrapperComponent = withStyles(styles)(HomeCards);
export default wrapperComponent;