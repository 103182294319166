import React, { 
  useState, 
  useEffect,
} from "react";
import { 
  Box, 
  IconButton, 
  Button
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Loading from "../../components/Loading";
import Header from "../../components/Header";
import api from "../../services/api";
import SnackBar from "../../util/SnackBar";
import ClearIcon from "@material-ui/icons/Clear";
import MrpStockRegister from "./MrpStockRegister/index";
import MrpStockUpdate from "./MrpStockUpdate/index";
import ConfirmDelete from "../../components/ConfirmDelete/index";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import Footer from "../../components/Footer";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import style from "./styles";

const styles = (theme) => style(theme);

function MrpStock({ classes }) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  const [material, setMaterial] = useState('');
  const [materialColor, setMaterialColor] = useState('');
  const [materialDescription, setMaterialDescription] = useState('');
  const [minimumStockDays, setMinimumStockDays] = useState('');
  const [maximumStockDays, setMaximumStockDays] = useState('');
  const [deliveryDays, setDeliveryDays] = useState('');
  const [itemStatus, setItemStatus] = useState('');  
  
  const [materialStocks, setMaterialStocks] = useState([]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState();
  const [rowInfo, setRowInfo] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    async function stockInfo() {
      try {
        setLoading(true);

        const response = await api
        .get('/mrp-stocks')
        .then((response) => response.data);
        setMaterialStocks(response);
      } catch (err) {
        SnackBar.error('Falha ao carregar informações do estoque.');
      } finally {
        setLoading(false);
      }
    }
    stockInfo();
  }, [reload]);

  const handleDelete = async (material, materialColor) => {
    try {
      await api.delete("/mrp-stocks", {
        data: { material, materialColor },
      });
      SnackBar.success('Material excluído com sucesso!');
      setReload(!reload);
    } catch (err) {
      SnackBar.error('Falha ao excluir material');
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenConfirmDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseConfirmDelete = () => {
    setOpenDelete(false);
  };

  const handleClickOpenUpdate = (
    material,
    materialColor,
    materialDescription,
    minimumStockDays,
    maximumStockDays,
    deliveryDays,
    itemStatus,
  ) => {
    setMaterial(material);
    setMaterialColor(materialColor);
    setMaterialDescription(materialDescription);
    setMinimumStockDays(minimumStockDays);
    setMaximumStockDays(maximumStockDays);
    setDeliveryDays(deliveryDays);
    setItemStatus(itemStatus);
    setOpenUpdate(true);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const columns = [
    {
      id: 'material',
      label: 'Material',
      align: 'center',
    },
    {
      id: 'materialColor',
      label: 'Cor',
      align: 'center',
    },
    {
      id: 'materialDescription',
      label: 'Descrição Material',
      align: 'center',
    },
    {
      id: 'minimumStockDays',
      label: 'Dias Estoque Mínimo',
      align: 'center',
    },
    {
      id: 'maximumStockDays',
      label: 'Dias Estoque Máximo',
      align: 'center',
    },
    {
      id: 'deliveryDays',
      label: 'Dias de Entrega',
      align: 'center',
    },
    {
      id: 'itemStatus',
      label: 'Status',
      align: 'center',
    },
  ];

  return (
    <>
      {loading ? (
        <>
          <Header />
          <Loading />
        </>
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100vh"
          >
            <Box
                display="flex"
                flexDirection="column"  
              >
                <Header />              
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
            >
              <Box className={classes.boxStyle}>
                <span className={classes.title}>
                  <AssessmentOutlinedIcon
                    fontSize="large"
                    className={classes.iconStyle}
                  />
                  Estoque de Reposição
                </span>
                <Button
                  variant="contained"
                  className={classes.button}
                  startIcon={<AddCircleIcon />}
                  onClick={handleClickOpen}
                >
                  NOVO
                </Button>
                <Dialog onClose={handleClose} open={open}>
                  <DialogContent dividers>
                    <MrpStockRegister 
                      onClickClose={() => {
                        handleClose();
                      }}
                      onReload={() => {
                        setReload(!reload);
                      }}
                    />
                  </DialogContent>
                </Dialog>
              </Box>

              <Dialog onClose={handleCloseUpdate} open={openUpdate} >
                <DialogContent dividers>
                  <MrpStockUpdate
                    material={material}
                    materialColor={materialColor}
                    materialDescription={materialDescription}
                    minimumStockDays={minimumStockDays}
                    maximumStockDays={maximumStockDays}
                    deliveryDays={deliveryDays}
                    itemStatus={itemStatus}
                    onReload={() => {
                      setReload(!reload);
                    }}
                    onClickClose={() => {
                      handleCloseUpdate();
                    }}
                  />
                </DialogContent>
              </Dialog>

              <Dialog onClose={handleCloseConfirmDelete} open={openDelete} >
                <DialogContent dividers>
                  <ConfirmDelete
                    onClickCancel={() => {
                      handleCloseConfirmDelete();
                    }}
                    onClickConfirm={() => {
                      handleDelete(rowInfo['material'], rowInfo['materialColor']);
                      handleCloseConfirmDelete();
                    }}
                    message="Deseja excluir este item?"
                  />
                </DialogContent>
              </Dialog>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                className={classes.container}
              >
                <Paper className={classes.root}>
                  <TableContainer className={classes.container}>
                    <InputBase
                      className={classes.inputStyle}
                      placeholder="Procurar por Material ou Desc Material"
                      value={search}
                      onChange={(ev) => setSearch(ev.target.value)}
                    />
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              style={{ width: 300 }}
                              className={classes.cellHeader}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                          <TableCell
                            style={{ maxWidth: 30 }}
                            className={classes.cellHeader}
                            align="center"
                          />
                          <TableCell
                            style={{ maxWidth: 30 }}
                            className={classes.cellHeader}
                          />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {materialStocks
                          .filter((user) => user['material']
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                          user['materialDescription']
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          )
                          .map((row, index) => {
                            return (
                              <TableRow hover key={index}>
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell
                                      key={index + column.id}
                                      align={column.align}
                                    >
                                      {value}
                                    </TableCell>
                                  );
                                })}
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() =>
                                      handleClickOpenUpdate(
                                        row['material'],
                                        row['materialColor'],
                                        row['materialDescription'],
                                        row['minimumStockDays'],
                                        row['maximumStockDays'],
                                        row['deliveryDays'],
                                        row['itemStatus']
                                      )
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() => {
                                      handleOpenConfirmDelete();
                                      setRowInfo(row);
                                    }}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </Box>
            <Footer />
          </Box>
        </>
      )}
    </>
  );
}

const wrapperComponent = withStyles(styles)(MrpStock);
export default wrapperComponent;
