import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { 
  Box,
  Typography, 
} from '@material-ui/core';

import style from './styles';

const styles = (theme) => style(theme);

function Footer({ 
  classes,
}) {
  return (
    <Box
      display="flex"
      flex-direction="column"
      justifyContent="flex-end"
      className={classes.footer}
    >
      <Typography>
        Gente é o que <span>soma</span>
      </Typography>
    </Box>
  );
}

const wrapperComponent = withStyles(styles)(Footer);
export default wrapperComponent;