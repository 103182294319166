import React, { 
  useState, 
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Menu from '@material-ui/icons/Menu';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import HistoryIcon from '@material-ui/icons/History';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import style from './styles';

import { logoff, UserConsulta, PermissionsGate, emailUsername } from '../../hooks/UserContextProvider';
import history from '../../services/history';
import { ROUTES } from '../../consts';


const styles = theme => style(theme);

function LeftMenu({
  classes,
}) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openNestedList, setOpenNestedList] = useState(false);

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  }

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  }
  
  const handleClick = () => {
    setOpenNestedList(!openNestedList);
  }

  let user = emailUsername();

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        className={classes.menuButton}
      >
        <Menu />
      </IconButton>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={openDrawer}
        classes={{
          paper: classes.menuStyle,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon className={classes.iconChevron} />
          </IconButton>
          <ListItem button onClick={() => { logoff(); history.push('/login')}}>
            <ListItemIcon className={classes.exitButton}>
              <ExitToAppIcon />
              <ListItemText className={classes.exitText} primary="Sair" />
            </ListItemIcon>
          </ListItem>
        </div> 
        <ListItem button onClick={() => history.push(ROUTES.HOME)} style={{padding: "4px 16px"}}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <Divider /> 
        { PermissionsGate() !== UserConsulta.EmailConsulta ? 
          (
            <>
              <ListItem button onClick={() => history.push(ROUTES.RESTOCK)}>
                <ListItemIcon>
                  <ShowChartIcon />
                </ListItemIcon>
                <ListItemText primary="Necessidade de reposição" />
              </ListItem>
              <ListItem button onClick={() => history.push(ROUTES.ORDER_HISTORY)}>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary="Histórico de pedidos" />
              </ListItem>
              <ListItem button onClick={() => history.push(ROUTES.SINGLE_ORDER)}>
                <ListItemIcon>
                  <AddShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Criar pedido avulso" />
              </ListItem>
              <ListItem button onClick={() => history.push(ROUTES.PREORDER_VALIDATION)}>
                <ListItemIcon>
                  <LibraryAddCheckIcon />
                </ListItemIcon>
                <ListItemText primary="Validação de pré-pedido" />
              </ListItem>
              <ListItem button onClick={() => history.push(ROUTES.MATERIAL_RESPONSIBLE)}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Administração de cargos" />
              </ListItem>
              <ListItem button onClick={() => history.push(ROUTES.MRPSTOCK)}>
                <ListItemIcon>
                  <PlaylistAddIcon />
                </ListItemIcon>
                <ListItemText primary="Estoque de reposição" />
              </ListItem>
              <ListItem button onClick={handleClick}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
                {openNestedList ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openNestedList} timeout="auto" unmountOnExit style={{minHeight:"auto"}}>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Consumo por produto" onClick={() => history.push(ROUTES.DASHBOARD_PRODUCT_CONSUMPTION)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Consumo por categoria" onClick={() => history.push(ROUTES.DASHBOARD_CATEGORY_CONSUMPTION)}/>
                  </ListItem>
                  {/* <ListItem className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Variação de preço por produto" 
                      // onClick={() => history.push(ROUTES.DASHBOARD_PRODUCT_PRICE_VARIATION)}
                    />
                  </ListItem> */}
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Curva de prazo de entrega" onClick={() => history.push(ROUTES.DASHBOARD_ITEMS_DEADLINE)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Top 10 Fornecedores" onClick={() => history.push(ROUTES.DASHBOARD_TOP_SUPPLIERS)}/>
                  </ListItem>
                  {/* <ListItem className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Curva de compras emitidas" 
                      // onClick={() => history.push(ROUTES.DASHBOARD_ORDERS)}
                    />
                  </ListItem> */}
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Curva de itens no CD" onClick={() => history.push(ROUTES.DASHBOARD_DISTRIBUTION_CENTER)}/>
                  </ListItem>
                  {/* <ListItem className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Comparativo de consumo" 
                      // onClick={() => history.push(ROUTES.DASHBOARD_CONSUMPTION_COMPARISON)}
                    />
                  </ListItem> */}
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Giro de estoque" onClick={() => history.push(ROUTES.DASHBOARD_STOCK_CONTROL)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Valores entrada por período" onClick={() => history.push(ROUTES.DASHBOARD_INCOMING_VALUES)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Quant. pedidos e valores" onClick={() => history.push(ROUTES.DASHBOARD_ORDERS_VALUES)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Consumo do subgrupo" onClick={() => history.push(ROUTES.DASHBOARD_SUBGROUP_CONSUMPTION)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Indicador de saúde por filial" onClick={() => history.push(ROUTES.DASHBOARD_HEALTH_INDICATOR)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Comparativo de pedidos" onClick={() => history.push(ROUTES.DASHBOARD_ORDERS_COMPARISON)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Base de Comsumo" onClick={() => history.push(ROUTES.DASHBOARD_CONSUMPTION_BASE)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Comparação de Estoque" onClick={() => history.push(ROUTES.DASHBOARD_COMPARISON_STOCK)}/>
                  </ListItem>
                </List>
              </Collapse> 
              <div style={{ flexGrow: 1 }} />
              <Divider />
              <ListItem style={{ padding: "4px 16px" }}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary={user} />
              </ListItem>
            </>
          ) : (
            <>
              <List component="div" disablePadding>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Consumo por produto" onClick={() => history.push(ROUTES.DASHBOARD_PRODUCT_CONSUMPTION)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Consumo por categoria" onClick={() => history.push(ROUTES.DASHBOARD_CATEGORY_CONSUMPTION)}/>
                  </ListItem>
                  {/* <ListItem className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Variação de preço por produto" 
                      // onClick={() => history.push(ROUTES.DASHBOARD_PRODUCT_PRICE_VARIATION)}
                    />
                  </ListItem> */}
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Curva de prazo de entrega" onClick={() => history.push(ROUTES.DASHBOARD_ITEMS_DEADLINE)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Top 10 Fornecedores" onClick={() => history.push(ROUTES.DASHBOARD_TOP_SUPPLIERS)}/>
                  </ListItem>
                  {/* <ListItem className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Curva de compras emitidas" 
                      // onClick={() => history.push(ROUTES.DASHBOARD_ORDERS)}
                    />
                  </ListItem> */}
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Curva de itens no CD" onClick={() => history.push(ROUTES.DASHBOARD_DISTRIBUTION_CENTER)}/>
                  </ListItem>
                  {/* <ListItem className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Comparativo de consumo" 
                      // onClick={() => history.push(ROUTES.DASHBOARD_CONSUMPTION_COMPARISON)}
                    />
                  </ListItem> */}
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Giro de estoque" onClick={() => history.push(ROUTES.DASHBOARD_STOCK_CONTROL)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Valores entrada por período" onClick={() => history.push(ROUTES.DASHBOARD_INCOMING_VALUES)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Quant. pedidos e valores" onClick={() => history.push(ROUTES.DASHBOARD_ORDERS_VALUES)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Consumo do subgrupo" onClick={() => history.push(ROUTES.DASHBOARD_SUBGROUP_CONSUMPTION)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Indicador de saúde por filial" onClick={() => history.push(ROUTES.DASHBOARD_HEALTH_INDICATOR)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Comparativo de pedidos" onClick={() => history.push(ROUTES.DASHBOARD_ORDERS_COMPARISON)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Base de Comsumo" onClick={() => history.push(ROUTES.DASHBOARD_CONSUMPTION_BASE)}/>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary="Comparação de Estoque" onClick={() => history.push(ROUTES.DASHBOARD_COMPARISON_STOCK)}/>
                  </ListItem>
              </List>
              <div style={{ flexGrow: 1 }} />
              <Divider />
              <ListItem style={{ padding: "4px 16px" }}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary={user} />
              </ListItem>
            </>
          ) 
        }  
      </Drawer>
    </>
  )
}

const wrapperComponent = withStyles(styles)(LeftMenu);
export default wrapperComponent;