const styles = (theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.7em',
      height: '0.7em',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  root: {
    width: '100%',
    boxShadow: 'none',
    '& .MuiTable-root': {
      borderCollapse: 'separate',
    },
    '& .MuiTableCell-root': {
      minWidth: '140px',
      maxWidth: '140px',
    },
    '& .MuiTableContainer-root': {
      height: '79vh',
    },
    '& .MuiIconButton-colorSecondary': {
      color: '#A9845F',
    },
    '& .MuiButtonBase-root.Mui-disabled': {
      color: '#E7E7E7',
    },
  },
  container: {
    margin: 10,
  },
  statusStyle: {
    fontSize: 14,
    marginRight: 10,
    borderRadius: 18,
  },
  boxFixed: {
    padding:0,
    position: 'sticky',
    left: 0,
    zIndex: 2,
    backgroundColor: '#fff',
  },
  headerFixed:{
    top:0,
    left: 0,
    position: 'sticky',
    zIndex: 3,
    padding: 0, 
  },
  boxStyle: {
    height: '3vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0px 10px 0px',
  },
  iconStyle: {
    color: '#707070',
    margin: 8,
  },
  cellHeader: {
    fontSize: 16,
    background: '#e4e4e4',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  cellBody: {
    borderBottom: '1px solid #fff',
    padding: 0,
  },
  title: {
    textAlign: 'center',
    fontSize: 22,
    color: '#707070',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    borderRadius: 12,
    boxShadow: 'none',
    margin: theme.spacing(1),
    backgroundColor: '#AD9078',
    '&:hover': {
      backgroundColor: '#A9845F',
      boxShadow: 'none',
    },
    '& span': {
      color: '#494D4B',
      fontWeight: 'bold',
      fontSize: 14,
    }
  },
  orderButton: {
    boxShadow: 'none',
    borderRadius: 12,
    margin: theme.spacing(1),
  },
  clearButton: {
    boxShadow: 'none',
    borderRadius: 12,
    margin: theme.spacing(1),
  },
  inputStyle: {
    width: 280,
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 10,
    border: '2px solid #F3F3F3',
    borderRadius: 10,
    backgroundColor: '#FDFDFD',
    '& .MuiInputBase-input': {
      marginLeft: 8,
      color: '#707070',
      fontSize: 14,
    },
  },
});

export default styles;