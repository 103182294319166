import React, {
  useEffect, 
  useState, 
} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Box,
  TextField,
  Typography,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import api from "../../../services/api";
import style from "./styles";
import Snackbar from "../../../util/SnackBar";

import { Autocomplete } from "@material-ui/lab/";

const styles = (theme) => style(theme);

function MrpStockRegister({ 
  classes, 
  onClickClose, 
  onReload 
}){
  const [material, setMaterial] = useState("");
  const [materialColor, setMaterialColor] = useState("");
  const [minimumStockDays, setMinimumStockDays] = useState("");
  const [maximumStockDays, setMaximumStockDays] = useState("");
  const [deliveryDays, setDeliveryDays] = useState("");
  const [itemStatus, setItemStatus] = useState("");
  const [materialValue, setMaterialValue] = useState([]);
  const [materialColorValue, setMaterialColorValue] = useState([]);

  useEffect(() => {
    async function getMaterial() {
      try {
        const response = await api
          .get("/mrp-stocks/material")
          .then((response) => response.data);
        setMaterialValue(response);
      } catch (err) {
        Snackbar.error("Falha ao carregar informações do Material.");
      }
    }
    getMaterial();
    
    async function getMaterialColor() {
      try {
        const response = await api
          .post("mrp-stocks/material-color/", {
            material,
          })
          .then((response) => response.data);
        setMaterialColorValue(response);
      } catch (err) {
        Snackbar.error("Falha ao carregar informações do Material Color.");
      }
    }
    getMaterialColor();

  }, [material]);

  const handleSubmit = async () => {
    try {
      await api.post("/mrp-stocks", {
        material: material,
        materialColor: materialColor,
        minimumStockDays: minimumStockDays,
        maximumStockDays: maximumStockDays,
        deliveryDays: deliveryDays,
        itemStatus: itemStatus,
      });
      Snackbar.success("Pedido criado com sucesso!");
      onClickClose();
      onReload();
    } catch (err) {
      Snackbar.error("Não foi possível criar o Material");
    }
  };

  return (
    <>
      <Box className={classes.boxStyle}>
        <Typography className={classes.title}>
          <span>CADASTRO DE ESTOQUE</span>
        </Typography>
        <Box
          className={classes.inputBox}
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ gap: 10 }}
        >
          <Autocomplete
            options={materialValue.map((index) => {
              return index.materialDescription;
            })}
            renderInput={(params) => <TextField {...params} label="Material" />}
            className={classes.completeInput}
            onChange={(e, material) => {
              setMaterial(material.split(' -')[0]);
            }}
          />
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel className={classes.inputLabel}>Cor Material</InputLabel>
            <Select
              defaultValue=""
              disableUnderline
              label="Cor Material"
              variant="filled"
              className={classes.inputStyle}
              onChange={(e) => setMaterialColor(e.target.value)}
            >
              {materialColorValue.map((index) => {
                return (
                  <MenuItem value={index.materialColor}>
                    {index.materialColor}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            label="Dias Estoque Mínimo"
            variant="filled"
            type="number"
            className={classes.inputStyle}
            onChange={(e) =>
              e.target.value < 0
                ? setMinimumStockDays((e.target.value = 0))
                : setMinimumStockDays(e.target.value)
            }
          />
          <TextField
            label="Dias Estoque Máximo"
            variant="filled"
            type="number"
            className={classes.inputStyle}
            onChange={(e) =>
              e.target.value < 0
                ? setMaximumStockDays((e.target.value = 0))
                : setMaximumStockDays(e.target.value)
            }
          />
          <TextField
            label="Dias de Entrega"
            variant="filled"
            type="number"
            className={classes.inputStyle}
            onChange={(e) =>
              e.target.value < 0
                ? setDeliveryDays((e.target.value = 0))
                : setDeliveryDays(e.target.value)
            }
          />
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel className={classes.inputLabel}>Status</InputLabel>
            <Select
              value={itemStatus}
              disableUnderline
              label="Status"
              variant="filled"
              className={classes.inputStyle}
              onChange={(e) => setItemStatus(e.target.value)}
            >
              <MenuItem value={"ATIVO"}>ATIVO</MenuItem>
              <MenuItem value={"INATIVO"}>INATIVO</MenuItem>
            </Select>
          </FormControl>
          <Button className={classes.buttonStyle} onClick={handleSubmit}>
            Adicionar
          </Button>
        </Box>
      </Box>
    </>
  );
}

const wrapperComponent = withStyles(styles)(MrpStockRegister);
export default wrapperComponent;