import React, { 
  useEffect, 
  useState 
} from 'react';
import { 
  Box, 
  TextField, 
  Typography, 
  Button,
  List,
  Checkbox,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import api from '../../../services/api';
import Snackbar from '../../../util/SnackBar';
import Loading from '../../../components/LoadingComponent';
import style from './styles';

const styles = (theme) => style(theme);

function MaterialResponsibleRegister({ 
  classes,
  onClickClose,
  onReload,
}) {
  const [loading, setLoading] = useState(false);
  const [groupInfos, setGroupInfos] = useState([]);
  const [email, setEmail] = useState('');
  const [checked, setChecked] = useState([]);
  const [step, setStep] = useState(1);
  const [userSubgroups, setUserSubgroups] = useState([]);

  useEffect(() => {
    async function groupInfos() {
      try {
        const response = await api.get('/material-groups/consumiveis')
        .then(response => response.data);

        setGroupInfos(response);
      } catch (err) {
        Snackbar.error('Falha ao carregar informações do responsável.');
      }
    }

    groupInfos();
  }, []);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSubmit = async () => {
    try {
      await api.post('/material-responsibles', {
        responsibles: [
          ...checked.map((subgroup) => {
            return {
              email,
              group: 'CONSUMIVEIS',
              subgroup,
            }
          })
        ]
      });
      Snackbar.success('Responsável criado com sucesso.');
      onClickClose();
      onReload();

    } catch (err) {
      Snackbar.error('Falha ao adicionar responsável.');
    }
  }

  const handleNext = async () => {
    setStep((oldValue) => {
      return oldValue + 1;
    })

    try {
      setLoading(true);
      const userSubgroupsResponse = await api.get('/material-responsibles/search', {
        params: {
          email
        }
      }).then(response => response.data);
      for (let responsavel of userSubgroupsResponse){
        let user = responsavel.email;
        if (email === user){
          Snackbar.error('Usuário ja cadastrado.');
          setStep((oldValue) => {
            return oldValue - 1;
          })
        } else {
          let userSubgroupsAux = userSubgroupsResponse.map((groupInfos) => {
            return groupInfos.subgroup
          })
          setUserSubgroups(userSubgroupsAux);
        }
      }
    } catch (err) {
      Snackbar.error('Falha ao obter informações do usuário.');
    }
    setLoading(false);
  }

  return (
    <>
      <Box>
        <Typography className={classes.title}>
          <span>CADASTRAR RESPONSÁVEL</span>
        </Typography>
        <Box
          className={classes.inputBox}
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="start"
          sx={{ gap: 10 }}
        >
          {step === 1 && 
            <Box
              display="flex"
              flexDirection="column"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography className={classes.titleBox}>Usuário</Typography>
                <TextField
                  label="Digite o e-mail"
                  variant="filled"
                  className={classes.inputStyle}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignSelf="flex-end"
              >
                <Button 
                  className={classes.buttonStyle}
                  onClick={handleNext}
                >
                  Próximo
                </Button>
              </Box>
            </Box>
          } 
          {step === 2 && 
            <Box
              display="flex"
              flexDirection="column"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography className={classes.titleBox}>Escolha os segmentos</Typography>
                {loading ? (
                  <Loading />
                ) : (
                  <List className={classes.subgroupStyle}>
                    {groupInfos.map((value, index) => {
                      const labelId = value;
                      return !userSubgroups.includes(value.subgroup) && 
                      (
                        <ListItem key={index} dense button onClick={handleToggle(value.subgroup)}>
                          <ListItemIcon>
                            <Checkbox
                              checked={checked.indexOf(value.subgroup) !== -1}
                              style={{color: "#A9845F"}}
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={`${value.subgroup}`} />
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
              >
                <Button 
                  className={classes.buttonStyle}
                  onClick={handleSubmit}
                >
                  Adicionar
                </Button>
              </Box>
            </Box>
          }
        </Box>
      </Box>
    </>
  );
}

const wrapperComponent = withStyles(styles)(MaterialResponsibleRegister);
export default wrapperComponent;