export const ROUTES = {
  LOGIN: '/login',
  HOME: '/home',
  MATERIAL_RESPONSIBLE: '/material-responsibles',
  MRPSTOCK: '/mrpStock',
  RESTOCK: '/restock',
  PREORDER_VALIDATION: '/preorder-validation',
  ORDER_HISTORY: '/order-history',
  SINGLE_ORDER: '/single-order',
  DASHBOARD_PRODUCT_CONSUMPTION: '/dashboard/product-consumption',
  DASHBOARD_CATEGORY_CONSUMPTION: '/dashboard/category-consumption',
  DASHBOARD_PRODUCT_PRICE_VARIATION: '/dashboard/product-price-variation',
  DASHBOARD_ITEMS_DEADLINE: '/dashboard/items-deadline',
  DASHBOARD_TOP_SUPPLIERS: '/dashboard/top-suppliers',
  DASHBOARD_ORDERS: '/dashboard/orders',
  DASHBOARD_DISTRIBUTION_CENTER: '/dashboard/distribution-center',
  DASHBOARD_CONSUMPTION_COMPARISON: '/dashboard/consumption-comparison',
  DASHBOARD_STOCK_CONTROL: '/dashboard/stock-control',
  DASHBOARD_INCOMING_VALUES: '/dashboard/incoming-values',
  DASHBOARD_ORDERS_VALUES: '/dashboard/orders-values',
  DASHBOARD_SUBGROUP_CONSUMPTION: '/dashboard/subgroup-consumption',
  DASHBOARD_HEALTH_INDICATOR: '/dashboard/health-indicator',
  DASHBOARD_ORDERS_COMPARISON: '/dashboard/orders-comparison',
  DASHBOARD_CONSUMPTION_BASE: '/dashboard/consumption-base',
  DASHBOARD_COMPARISON_STOCK: '/dashboard/comparison-stock',
}