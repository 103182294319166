import React from 'react';
import {
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';

import { ROUTES } from '../consts';

import Login from '../pages/Login';
import MrpStock from '../pages/MrpStock';
import MaterialResponsible from '../pages/MaterialResponsible';
import Restock from '../pages/Restock';
import PreOrderValidation from '../pages/PreOrderValidation';
import OrderHistory from '../pages/OrderHistory';
import SingleOrder from '../pages/SingleOrder'
import DashboardProductConsumption from '../pages/Dashboards/DashboardProductConsumption';
import DashboardCategoryConsumption from '../pages/Dashboards/DashboardCategoryConsumption';
import DashboardProductPriceVariation from '../pages/Dashboards/DashboardProductPriceVariation';
import DashboardItemsDeadline from '../pages/Dashboards/DashboardItemsDeadline';
import DashboardTopSuppliers from '../pages/Dashboards/DashboardTopSuppliers';
import DashboardOrders from '../pages/Dashboards/DashboardOrders';
import DashboardDistributionCenter from '../pages/Dashboards/DashboardDistributionCenter';
import DashboardConsumptionComparison from '../pages/Dashboards/DashboardConsumptionComparison';
import DashboardStockControl from '../pages/Dashboards/DashboardStockControl';
import DashboardIncomingValues from '../pages/Dashboards/DashboardIncomingValues';
import DashboardOrdersValues from '../pages/Dashboards/DashboardOrdersValues';
import DashboardSubgroupConsumption from '../pages/Dashboards/DashboardSubgroupConsumption';
import DashboardHealthIndicator from '../pages/Dashboards/DashboardHealthIndicator';
import DashboardOrdersComparison from '../pages/Dashboards/DashboardOrdersComparison';
import DashboardConsumptionBase from '../pages/Dashboards/DashboardConsumptionBase';
import DashboardComparisonStock from '../pages/Dashboards/DashboardComparisonStock';
import HomeCards from '../pages/HomeCards';

import { IsAuthenticated, Role } from '../hooks/UserContextProvider';

function PrivateRoute({
  component: Component,
  ...rest
}) {
  return <Route
    {...rest}
    component={props =>
      IsAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
}

export default function Routes() {
  const ResolveNotFoundRoute = () => {
    if (!IsAuthenticated) {
      return <Redirect to={ROUTES.LOGIN} />;
    } else {
      return <Redirect to={ROUTES.HOME} />;
    }
  };

  return (
    <Switch>
      <Route
        exact
        path={ROUTES.LOGIN}
        component={Login}
      />

      <PrivateRoute
        exact
        path={ROUTES.HOME}
        roles={[Role.Consulta]}
        component={HomeCards}
      />

      <PrivateRoute
        exact
        path={ROUTES.MATERIAL_RESPONSIBLE}
        roles={[Role.Escrita]}
        component={MaterialResponsible}
      />

      <PrivateRoute
        exact
        path={ROUTES.MRPSTOCK}
        roles={[Role.Escrita]}
        component={MrpStock}
      />

      <PrivateRoute
        exact
        path={ROUTES.RESTOCK}
        roles={[Role.Escrita]}
        component={Restock}
      />

      <PrivateRoute
        exact
        path={ROUTES.PREORDER_VALIDATION}
        roles={[Role.Escrita]}
        component={PreOrderValidation}
      />

      <PrivateRoute
        exact
        path={ROUTES.ORDER_HISTORY}
        roles={[Role.Escrita]}
        component={OrderHistory}
      />

      <PrivateRoute
        exact
        path={ROUTES.SINGLE_ORDER}
        roles={[Role.Escrita]}
        component={SingleOrder}
      />

      <PrivateRoute
        exact
        path={ROUTES.DASHBOARD_PRODUCT_CONSUMPTION}
        roles={[Role.Consulta]}
        component={DashboardProductConsumption}
      />

      <PrivateRoute
        exact
        path={ROUTES.DASHBOARD_CATEGORY_CONSUMPTION}
        roles={[Role.Consulta]}
        component={DashboardCategoryConsumption}
      />

      <PrivateRoute
        exact
        path={ROUTES.DASHBOARD_PRODUCT_PRICE_VARIATION}
        roles={[Role.Consulta]}
        component={DashboardProductPriceVariation}
      />

      <PrivateRoute
        exact
        path={ROUTES.DASHBOARD_ITEMS_DEADLINE}
        roles={[Role.Consulta]}
        component={DashboardItemsDeadline}
      />

      <PrivateRoute
        exact
        path={ROUTES.DASHBOARD_TOP_SUPPLIERS}
        roles={[Role.Consulta]}
        component={DashboardTopSuppliers}
      />

      <PrivateRoute
        exact
        path={ROUTES.DASHBOARD_ORDERS}
        roles={[Role.Consulta]}
        component={DashboardOrders}
      />

      <PrivateRoute
        exact
        path={ROUTES.DASHBOARD_DISTRIBUTION_CENTER}
        roles={[Role.Consulta]}
        component={DashboardDistributionCenter}
      />

      <PrivateRoute
        exact
        path={ROUTES.DASHBOARD_CONSUMPTION_COMPARISON}
        roles={[Role.Consulta]}
        component={DashboardConsumptionComparison}
      />

      <PrivateRoute
        exact
        path={ROUTES.DASHBOARD_STOCK_CONTROL}
        roles={[Role.Consulta]}
        component={DashboardStockControl}
      />

      <PrivateRoute
        exact
        path={ROUTES.DASHBOARD_INCOMING_VALUES}
        roles={[Role.Consulta]}
        component={DashboardIncomingValues}
      />

      <PrivateRoute
        exact
        path={ROUTES.DASHBOARD_ORDERS_VALUES}
        roles={[Role.Consulta]}
        component={DashboardOrdersValues}
      />

      <PrivateRoute
        exact
        path={ROUTES.DASHBOARD_SUBGROUP_CONSUMPTION}
        roles={[Role.Consulta]}
        component={DashboardSubgroupConsumption}
      />

      <PrivateRoute
        exact
        path={ROUTES.DASHBOARD_HEALTH_INDICATOR}
        roles={[Role.Consulta]}
        component={DashboardHealthIndicator}
      />

      <PrivateRoute
        exact
        path={ROUTES.DASHBOARD_ORDERS_COMPARISON}
        roles={[Role.Consulta]}
        component={DashboardOrdersComparison}
      />

      <PrivateRoute
        exact
        path={ROUTES.DASHBOARD_CONSUMPTION_BASE}
        roles={[Role.Consulta]}
        component={DashboardConsumptionBase}
      />

      <PrivateRoute
        exact
        path={ROUTES.DASHBOARD_COMPARISON_STOCK}
        roles={[Role.Consulta]}
        component={DashboardComparisonStock}
      />

      <ResolveNotFoundRoute />
    </Switch>
  );
}