const styles = (theme) => ({
    '@global': {
      "* .MuiFormLabel-root.Mui-focused": {
        color: "#AAAAAA",
      },
      "* .MuiFormLabel-root": {
        color: "#AAAAAA",
      }
    },
    boxTitulo: {
      backgroundColor: '#e1e1e1',
      padding: '10px',
      marginBottom: '15px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
  
    },
    cellHeader: {
      fontSize: 16,
      background: '#e4e4e4',
      textAlign: 'center',
    },
    title: {
      fontSize: 22,
      marginLeft: '20px',
      color: 'red',
    },
    obs: {
      fontSize: 20,
      color: 'red',
    }
  });
  
  export default styles;