import React, { 
  useState, 
  useEffect, 
  useRef,
} from "react";

import { 
  Box, 
  InputBase, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  useMediaQuery,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import TimelineIcon from '@material-ui/icons/Timeline';

import Loading from "../../../components/Loading";
import Header from "../../../components/Header";
import api from "../../../services/api";
import Footer from "../../../components/Footer";
import SnackBar from "../../../util/SnackBar";

import FilterDate from '../../../components/FilterDate'

import {
  formatFirstDayCurrentMonth,
  formatCurrentDay
} from '../../../util/UtilDates';

import style from "./styles";

const styles = (theme) => style(theme);

function DashboardConsumptionComparison({ 
  classes, 
}) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [materialInfos, setMaterialInfos] = useState([]);
  const [dateInitial, setDateInitial] = useState(formatFirstDayCurrentMonth());
  const [dateFinal, setDateFinal] = useState(formatCurrentDay());
  const dateInitialFieldReference = useRef(null);
  const dateFinalFieldReference = useRef(null);

  const matchMinW640 = useMediaQuery('(min-width:640px)');

  useEffect(() => {
    async function getTopSuppliers() {
      try {
        setLoading(true);
        const response = await api.get(`/dashboard/consumption-comparison?dateInitial=${dateInitial}&dateFinal=${dateFinal}`)
          .then((response) => response.data);
          
        setMaterialInfos(response);
      } catch (err) {
        SnackBar.error("Falha ao carregar informações do Dashboard.");

      } finally {
        setLoading(false);
      }
    }
    getTopSuppliers();
  }, [dateInitial, dateFinal]);

  const onClickFilterDate = () => {
    setDateInitial(dateInitialFieldReference.current.value);
    setDateFinal(dateFinalFieldReference.current.value);
  }

  const columns = [
    {
      id: "material",
      label: "Material",
      align: "center",
    },
    {
      id: "materialDescription",
      label: "Descrição Material",
      align: "center",
    },
  ];

  return (
    <>
      {loading ? (
        <>
          <Header />
          <Loading />
        </>
      ) : (
        <>
          <Box 
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100vh"
          >
            <Box
              display="flex"
              flexDirection="column"  
            >
              <Header />              
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
            >
              <Box className={classes.boxStyle}>
                <span className={classes.title}>
                  <TimelineIcon 
                    fontSize="large" 
                    className={classes.iconStyle} 
                  />
                  Comparativo de consumo dos itens com os meses dos anos anteriores
                </span>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                margin="10px"
              >
                <Paper className={classes.root}>
                  <TableContainer>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="space-between"
                      alignItems="center"
                      style={matchMinW640 ? {} : { justifyContent: 'center' }}
                    >
                      <InputBase
                        className={classes.inputStyle}
                        placeholder="Procurar por Material ou Desc Material"
                        value={search}
                        onChange={(ev) => setSearch(ev.target.value)}
                      />
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        
                        <FilterDate
                          dateInitial={dateInitial}
                          dateFinal={dateFinal}
                          onClick={onClickFilterDate}
                          inputReferenceInitial={dateInitialFieldReference}
                          inputReferenceFinal={dateFinalFieldReference}
                        />

                      </Box>
                    </Box>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              style={{ width: 300 }}
                              className={classes.cellHeader}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {materialInfos.filter((material) => material['material'].toLowerCase().includes(search.toLowerCase()) ||
                        material['materialDescription'].toLowerCase().includes(search.toLowerCase())
                          ).map((row, index) => {
                          return (
                            <TableRow hover key={index}>
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell key={index + column.id} align={column.align}>
                                    {value} 
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </Box>
            <Footer />
          </Box>
        </>
      )}
    </>
  );
}

const wrapperComponent = withStyles(styles)(DashboardConsumptionComparison);
export default wrapperComponent;